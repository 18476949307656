import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { PRODUCT_TYPES } from 'src/app/shared/constants/product-types.const';
import { Invoice, NetSuiteCustomer, PagedList, Status, ProductType } from 'src/app/shared/models/PlatformAccountingContract';
import { getProductName } from 'src/app/shared/services/utilities';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AppId, RouteResolverService, SkyKickProductPage, SortingRack, TaskManagerService } from '@skykick/core';
import { BillingContactComponent } from '../shared/modals/billing-contact/billing-contact.component';
import { InvoiceType, ProductFilter } from '../shared/models/typings';
import { BillingResourcesService } from '../shared/services/billing-resources.service';

@Component({
    selector: '[invoices]',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent extends SkyKickProductPage implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    private overlayConfig: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-panel modal-right', size: 'sm' };
    private pageSize = 10;
    private pageNumber = 1;
    invoices: Invoice[] = [];
    isLoading: boolean = true;
    isLoadingMore: boolean = false;
    Status = Status;
    pagedList: PagedList<Invoice>;
    sortingRack: SortingRack<Invoice>;
    moment: any = moment;
    invoiceNavs: InvoiceType[] = [
        { name: 'skBilling.invoicespage.due', value: Status.Open, active: true },
        { name: 'skBilling.invoicespage.paid', value: Status.Paid, active: false }
    ];
    productTypes = PRODUCT_TYPES;
    productType: ProductFilter;
    netSuiteCustomer: NetSuiteCustomer;
    invoiceOverdue: boolean = false;
    connectWisePartner: any; //from PAPI

    private getInvoices(status: Status) {
        this.isLoadingMore = true;
        this.invoiceOverdue = false;
        this.billingResourcesService.getInvoices(status, this.pageSize, this.pageNumber, this.productType).pipe(
            takeUntil(this.onDestroy$),
            tap(res => {
                this.pagedList = res;
            }),
            map(res => {
                return res.items;
            }),
            tap(res => {
                res.forEach(item => {
                    this.invoices.push(item);
                    if (!item.paidDate && moment(item.dueDate).isBefore()) {
                        this.invoiceOverdue = true;
                    }
                });
            })
        ).subscribe(() => {
            this.isLoading = false;
            this.isLoadingMore = false;
        });

    }

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected taskManagerService: TaskManagerService,
        private billingResourcesService: BillingResourcesService,
        private modalService: NgbModal,
        private routeResolverService: RouteResolverService
    ) {
        super(taskManagerService)
    }

    ngOnInit(): void {
        this.activatedRoute.data.pipe(
            takeUntil(this.onDestroy$),
            tap(res => {
                this.netSuiteCustomer = res.netSuiteCustomer;
                this.productType = this.productTypes[0];
                this.connectWisePartner = res.connectWisePartner;
            })
        ).subscribe(() => {
            this.getInvoices(Status.Open);
        });
    }

    invoiceNavigation(item: InvoiceType, event: Event) {
        event.preventDefault();
        if (item.active) {
            return;
        }
        this.invoiceNavs.forEach(item => {
            item.active = false;
        });
        item.active = true;
        switch (item.value) {
            case Status.Open: {
                this.isLoading = true;
                this.invoices.length = 0;
                this.pageNumber = 1;
                this.getInvoices(Status.Open);
                break;
            }
            case Status.Paid: {
                this.isLoading = true;
                this.invoices.length = 0;
                this.pageNumber = 1;
                this.getInvoices(Status.Paid);
                break;
            }
        }
    }

    editBillingContact(event: Event) {
        event.preventDefault();
        const modalRef = this.modalService.open(BillingContactComponent, this.overlayConfig);
        modalRef.componentInstance.billingContact = this.netSuiteCustomer.emailAddress;
        modalRef.result.then(result => {
            this.netSuiteCustomer.emailAddress = result;
        }).catch((res) => { });

    }

    getProductDisplayName(productType: ProductType): string {
        var productName: string = getProductName(productType);
        return productName;
    }

    getEmailAddress(): string {
        var email = null;
        if (this.netSuiteCustomer && this.netSuiteCustomer.emailAddress && this.netSuiteCustomer.isInvoicingEnabled) {
            email = this.netSuiteCustomer.emailAddress;
        }
        return email;
    }

    showMore() {
        this.pageNumber = this.pagedList.currentPage + 1;
        this.getInvoices(this.getInvoiceStatus());
    }

    getInvoiceStatus() {
        let invoiceNav = this.invoiceNavs.find(item => item.active);
        return invoiceNav.value;
    }

    openInvoicesTabActive() {
        return this.getInvoiceStatus() === Status.Open;
    }

    paidInvoicesTabActive() {
        return this.getInvoiceStatus() === Status.Paid;
    }

    transformInvoices(invoices: Invoice[], productType: ProductType): Invoice[] {
        return invoices.filter(invoice => {
            if (!productType) {
                return invoice;
            }
            return invoice.productType === productType;
        });
    }

    filterInvoices(item: ProductFilter) {
        this.isLoading = true;
        this.invoices.length = 0;
        this.pageNumber = 1;
        this.productType = item;
        this.getInvoices(this.getInvoiceStatus());
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    viewConnectWiseIntegration() {
        window.location.href = this.routeResolverService.generateRatRoute(AppId.Portal, '/settings/integrations/connectwise');
    }

}
