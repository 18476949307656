<div class="pb-200 px-200 max-width-md min-width-md">
    <ng-container *ngIf="invoice else empty">
        <div>
            <table class="table table-hover sk-standard-wrap-table sk-sortable">
                <thead class="thead-white thead-sm">
                    <tr>
                        <th translate>skBilling.itemized_detail_page.order_id</th>
                        <th translate>skBilling.itemized_detail_page.customer_name</th>
                        <th translate>skBilling.itemized_detail_page.order_type</th>
                        <th class="text-end" translate>skBilling.itemized_detail_page.quantity</th>
                        <th class="text-end" translate>skBilling.itemized_detail_page.cost/unit</th>
                        <th class="text-end" translate>skBilling.itemized_detail_page.total_amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoice.lineItems; let i = index">
                        <td id="orderNumber{{i}}">{{item.orderNumber}}</td>
                        <td id="endCustomer{{i}}">{{item.endCustomer}}</td>
                        <!-- Showing 'productName' instead of 'item.orderType' for CM and SM because we get some empty properties from API -->
                        <td id="orderType{{i}}" translate>{{showProductName ? productName : item.orderType}}</td>
                        <td id="quantity{{i}}" class="text-end">{{item.quantity}}</td>
                        <td id="rate{{i}}" class="text-end">{{item.rate | currency: invoice.currencyCode}}</td>
                        <td id="amount{{i}}" class="text-end">{{item.amount | currency: invoice.currencyCode}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="mt-300" *ngIf="invoice.productType === ProductType.CloudManager || invoice.productType === ProductType.SecurityManager">
            <div class="text-muted" translate>skBilling.itemized_detail_page.item_name</div>
    
            <ng-container *ngFor="let item of invoice.lineItems; let i = index">
                <ng-container *ngIf="!item.prorated">
                    <hr/>
                    <h5>{{item.itemName}}</h5>
                    <p class="text-muted">{{item.description}}</p>
                    <div class="container px-0">
                        <div class="row mt-50" *ngFor="let sub of item.subItems; let j = index">
                            <div class="col-10">{{sub.itemName}}</div>
                            <div id="subQuantity{{j}}" class="col-2 text-end">{{sub.quantity}}</div>
                        </div>    
                    </div>    
                </ng-container>
            </ng-container>

            <hr/>
        
            <ng-container *ngIf="invoice.productType === ProductType.CloudManager && platformPlanDetail?.discount">
                <h5 id="platformDiscounts" translate>skBilling.itemized_detail_page.platform_discounts</h5>
                <div class="container px-0">
                    <div class="row mt-50">
                        <div class="col-10" translate>skBilling.itemized_detail_page.cloud_backup</div>
                        <div class="col-2 text-end"><strong>{{platformPlanDetail.discount.backup}}%</strong></div>
                    </div>    
                    <div class="row mt-50">
                        <div class="col-10" translate>skBilling.itemized_detail_page.migrations</div>
                        <div class="col-2 text-end"><strong>{{platformPlanDetail.discount.migrations}}%</strong></div>
                    </div>    
                    <div class="row mt-50">
                        <div class="col-10" translate>skBilling.itemized_detail_page.bundles</div>
                        <div class="col-2 text-end"><strong>{{platformPlanDetail.discount.bundle}}%</strong></div>
                    </div>    
                </div>
            
                <hr/>    
            </ng-container>
        </div>
    </ng-container>

    <ng-template #empty>
        <sk-text-spinner message="{{'skBilling.itemized_detail_page.grabbing_itemized_etails' | translate}}"></sk-text-spinner>
    </ng-template>

</div>
