import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Status } from 'src/app/shared/models/PlatformAccountingContract';
import { BillingFrequency, OriginationSource, PlatformPlanDetails } from 'src/app/shared/models/SubscriptionManagementContract';
import { BillingResourcesService } from 'src/app/shared/services/billing-resources.service';
import { TranslateService } from '@ngx-translate/core';
import { AppId, DestructiveModalComponent, SimpleModalComponent, RouteResolverService, SkyKickModal, SkyKickModalService, SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { SkyKickModalDestructiveOptions, SkyKickModalOptions } from '@skykick/core/lib/modals/core/skykick-modal-options';
import { SkyKickProduct } from 'src/app/shared/models/SkyKickProduct.model';
import { SkyKickProducts } from 'src/app/shared/constants/SkyKickProducts.const';
import { DbFormattedSubscriptionState } from 'src/app/shared/models/DbFormattedSubscriptionState';
import { SurveyService } from 'src/app/surveys/survey.service';
import { HotjarEvents } from 'src/app/surveys/hotjar-events';

@Component({
    selector: 'sk-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent extends SkyKickProductPage implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    moment: any = moment;
    OriginationSource = OriginationSource;
    State = DbFormattedSubscriptionState;
    Status = Status;
    BillingFrequency = BillingFrequency;
    notPurchasedSkyKickProducts: SkyKickProduct[];
    platformPlanDetails: PlatformPlanDetails[];
    companyInfo: any;//coming from Papi
    distributorList: Array<any>;//coming from Papi
    distributor: any;//coming from Papi

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected taskManagerService: TaskManagerService,
        private billingResourcesService: BillingResourcesService,
        private routeResolverService: RouteResolverService,
        private skyKickModalService: SkyKickModalService,
        private translateService: TranslateService,
        private surveyService: SurveyService,
        private toastrService: ToastrService
    ) {
        super(taskManagerService);
    }

    ngOnInit(): void {
        this.notPurchasedSkyKickProducts = SkyKickProducts;

        this.activatedRoute.data.pipe(
            takeUntil(this.onDestroy$),
            tap(res => {
                this.platformPlanDetails = res.platformPlanDetails;
                this.companyInfo = res.companyInfo;
                this.distributorList = res.distributorInfo;

                this.platformPlanDetails?.forEach(item => {
                    if (this.isMarketplaceSubscription(item)) {
                        this.distributor = this.distributorList.find(({id}) => id === item.distributorId);
                    }
                    this.notPurchasedSkyKickProducts = this.notPurchasedSkyKickProducts.filter(({productTypeId}) => productTypeId !== item.productTypeId);
                });
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    viewPlanOptions(productTypeId: string) {
        const productRouteName = this.getSkyKickProduct(productTypeId).routeName;
        window.location.href = this.routeResolverService.generateRatRoute(AppId.Purchase, `/overview/${productRouteName}`);
    }

    isActive(platformPlan: PlatformPlanDetails): boolean {
        return platformPlan.state === DbFormattedSubscriptionState.Active ||
               platformPlan.state === DbFormattedSubscriptionState.RenewalApproaching;
    }

    cancelSubscription(e: Event, platformPlanDetails: PlatformPlanDetails) {
        e.preventDefault();
        let warningModal: SkyKickModal<DestructiveModalComponent, void>;
        const options: SkyKickModalDestructiveOptions = {
            body: `<p class="mb-200">${this.translateService.instant('platform_plan_page.by_canceling_your_subscription', { productType: this.getSkyKickProduct(platformPlanDetails.productTypeId).displayName, date: moment(platformPlanDetails.termStartDate).format('ll')})}</p>`,
            title: this.translateService.instant('platform_plan_page.cancel_subscription?'),
            btnLabel: this.translateService.instant('platform_plan_page.yes_cancel_subscription'),
            alternative: {
                btnLabel: this.translateService.instant('platform_plan_page.no_keep_subscription'),
                btnCallback: () => {
                    warningModal.dismiss();
                }
            }
        };
        warningModal = this.skyKickModalService.destructive(options);
        warningModal.result.then(res => {
            if (res.wasClosed) {
                this.billingResourcesService.setRenewal(platformPlanDetails.id, false)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        complete: () => {
                            platformPlanDetails.doNotRenew = true;
                            this.toastrService.success(this.translateService.instant('platform_plan_page.cancel_success'));
                            this.surveyService.event(HotjarEvents.DoNotRenewNdays);
                        }, 
                        error: () => {
                            this.toastrService.error(this.translateService.instant('platform_plan_page.cancel_error'));
                        }
                    });
            }
        });
    }

    renewSubscription(e: Event, platformPlanDetails: PlatformPlanDetails) {
        e.preventDefault();
        let infoModal: SkyKickModal<SimpleModalComponent, void>;
        const options: SkyKickModalOptions = {
            body: `<p class="mb-200">${this.translateService.instant('platform_plan_page.renewal_flow.renewing_the_subscription_will_resume')}</p>`,
            title: this.translateService.instant('platform_plan_page.renewal_flow.renew_subscription?'),
            btnLabel: this.translateService.instant('platform_plan_page.renewal_flow.yes_renew_subscription'),
            alternative: {
                btnLabel: this.translateService.instant('platform_plan_page.renewal_flow.cancel'),
                btnCallback: () => {
                    infoModal.dismiss();
                }
            }
        };
        infoModal = this.skyKickModalService.simple(options);
        infoModal.result.then(res => {
            if (res.wasClosed) {
                this.billingResourcesService.setRenewal(platformPlanDetails.id, true)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        complete: () => {
                            platformPlanDetails.doNotRenew = false;
                            this.toastrService.success(this.translateService.instant('platform_plan_page.renewal_flow.renew_success'));
                        }, 
                        error: () => {
                            this.toastrService.error(this.translateService.instant('platform_plan_page.renewal_flow.renew_error'));
                        }
                    });
            }
        });
    }

    turnOffRenew(e: Event, platformPlanDetails: PlatformPlanDetails) {
        e.preventDefault();
        let warningModal: SkyKickModal<DestructiveModalComponent, void>;
        const options: SkyKickModalDestructiveOptions = {
            body: `<p class="mb-200">${this.translateService.instant('platform_plan_page.by_not_renewing_your_subscription', { productType: this.getSkyKickProduct(platformPlanDetails.productTypeId).displayName, date: this.formatMoment(this.getCommitmentRenewalDate(platformPlanDetails))})}</p>`,
            title: this.translateService.instant('platform_plan_page.do_not_renew_subscription?'),
            btnLabel: this.translateService.instant('platform_plan_page.yes_do_not_renew_subscription'),
            alternative: {
                btnLabel: this.translateService.instant('platform_plan_page.no_keep_subscription'),
                btnCallback: () => {
                    warningModal.dismiss();
                }
            }
        };
        warningModal = this.skyKickModalService.destructive(options);
        warningModal.result.then(res => {
            if (res.wasClosed) {
                this.billingResourcesService.setRenewal(platformPlanDetails.id, false)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    complete: () => {
                        platformPlanDetails.doNotRenew = true;
                        this.toastrService.success(this.translateService.instant('platform_plan_page.not_renew_success'));
                        this.surveyService.event(HotjarEvents.DoNotRenewActive);
                    }, 
                    error: () => {
                        this.toastrService.error(this.translateService.instant('platform_plan_page.not_renew_error'));
                    }
                });
            }
        });
    }

    goToProduct(productTypeId: string, $event: Event) {
        $event.preventDefault();
        var productLink = this.getProductLink(productTypeId);
        if (productLink) {
            window.location.href = productLink;
        }
    }

    getProductLink(productTypeId: string) {
        if (productTypeId === SkyKickProducts[0].productTypeId || productTypeId === SkyKickProducts[1].productTypeId) {
            // Take me to Cloud Manager or Security Manager
            // NOTE: Security Manager won't have it's own domain for a while. It's a part of CM domain and there is no dedicated
            // introduction page for Security Manager, so for now we redirect to CM introduction page.
            return this.routeResolverService.generateRatRoute(AppId.CloudManager, '/introduction');
        }
    }

    getSkyKickProduct(productTypeId: string) {
        return SkyKickProducts.find(({productTypeId: id}) => id === productTypeId);
    }

    getDiscountTitleText(discountType: string) {
        switch(discountType) {
            case 'backup':
                return this.translateService.instant('platform_plan_page.cloud_backup');
            case 'migrations':
                return this.translateService.instant('platform_plan_page.migrations');
            case 'bundle':
                return this.translateService.instant('platform_plan_page.bundles');
        }
    }

    hasActiveSubscription() {
        return !!this.platformPlanDetails?.length;
    }

    formatDate(date: string): string {
          return this.formatMoment(moment(date));
    }

    formatMoment(date: moment.Moment): string {
        return date.format('ll');
    }

    getCommitmentRenewalDate(platformPlanDetails: PlatformPlanDetails): moment.Moment {
        if (platformPlanDetails.billingFrequency !== BillingFrequency.AnnualMonthlyCommit){
            // For Monthly and Annual commitment renewal date is the same as nextChargeDate
            return moment(platformPlanDetails.nextChargeDate);
        }
        else {
            // For AMB 'nextChargeDate' is basically renewal of Monthly billing cycle, but on
            // UI we need to show when Annual commitment cycle ends, so we calculate it here
            let renewalDate = moment(platformPlanDetails.termStartDate).add(1, 'y');

            while(renewalDate < moment.utc()){
                renewalDate = renewalDate.add(1, 'y');
            }

            return renewalDate;
        }
    }

    showFooterWithActions(platformPlanDetail: PlatformPlanDetails): boolean{
        const activeFreeSubCanBeCancelled = !platformPlanDetail.doNotRenew && platformPlanDetail.isCancellable && platformPlanDetail.state === this.State.ActiveFree;
        const activeSubCanBeRenewed = !platformPlanDetail.doNotRenew && this.isActive(platformPlanDetail);

        // Only Traditional subscriptions can be edited from UI,
        // Marketplace subscriptions should only be changed from Marketplace 
        return !this.isMarketplaceSubscription(platformPlanDetail) && (
            platformPlanDetail.doNotRenew ||
            activeSubCanBeRenewed ||
            activeFreeSubCanBeCancelled);
    }

    isMarketplaceSubscription(platformPlanDetail: PlatformPlanDetails): boolean{
        return platformPlanDetail.originationSource === this.OriginationSource.Marketplace;
    }
}
