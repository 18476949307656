import { ResolveFn } from '@angular/router';

import { of } from 'rxjs';

export const CompanyInfoLocalResolver: ResolveFn<any> = () => {
    return of({
        name: "Test Local Company Name",
        address: "200 W Thomas St",
        address2: "Suite 400",
        city: "Seattle",
        selectedCountryId: 772370000,
        selectedStateId: "Washington",
        postalCode: "98119",
        partnerContactEmail: "pi.automation@skykick.com",
        phone: "888 888 8888",
        numOfEmployees: 0,
        selectedO365ExperienceId: 0,
        selectedHowDidYouId: 0,
        mpnId: "3285645",
        selectedO365CountryId: 772370000,
        partnerId: "e439b422-0b56-e711-8107-e0071b71f9b1"
    });
};
