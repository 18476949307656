import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CustomerPaymentProfile,
} from 'src/app/shared/models/PlatformAccountingContract';
import { environment } from 'src/environments/environment';

import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { ReactiveFormStatus } from '../../models/ReactiveFormStatus';
import {
  BillingResourcesService,
} from '../../services/billing-resources.service';
import { CreditCardService } from '../creditcard.service';

@Component({
    selector: 'sk-customer-payment-profiles',
    templateUrl: './customer-payment-profiles.component.html',
    styleUrls: ['./customer-payment-profiles.component.scss'],
    providers: [NgbPopoverConfig]
})
export class CustomerPaymentProfilesComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    form: UntypedFormGroup;
    customerPaymentProfiles: CustomerPaymentProfile[] = [];
    isLoading: boolean = true;
    @Input() disable?: boolean;
    @Input() displayOnly?: boolean;

    constructor(
        private billingResourcesService: BillingResourcesService,
        private formBuilder: UntypedFormBuilder,
        private renderer: Renderer2,
        private creditCardService: CreditCardService,
        private ngbPopoverConfig: NgbPopoverConfig,
        private routeResolverService: RouteResolverService
    ) {
        ngbPopoverConfig.placement = 'right';
    }

    addJsToHead(src: string): HTMLScriptElement {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        this.renderer.appendChild(document.head, script);
        return script;
    }

    ngOnInit(): void {
        this.addJsToHead(environment.apis.acceptJS);

        this.form = this.formBuilder.group({
            paymentProfile: [null, [Validators.required]]
        });
        this.form.statusChanges.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(result => {
            if (result === ReactiveFormStatus.VALID) {
                this.creditCardService.setCustomerPaymentProfile(this.form.get('paymentProfile').value);
            } else {
                this.creditCardService.setCustomerPaymentProfile(null);
            }
        });

        this.getPaymentProfiles();

        this.creditCardService.disableCustomerPaymentProfiles$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            if (res) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        });
    }

    manageCreditCards() {
        window.location.href = this.routeResolverService.generateRatRoute(AppId.Portal, '/settings/payment');
    }

    getPaymentProfiles() {
        this.isLoading = true;
        this.creditCardService.setCustomerPaymentProfile(null);
        this.billingResourcesService.getPaymentProfiles().pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            this.customerPaymentProfiles = res;
            this.isLoading = false;
            if (this.disable) {
                this.form.disable();
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

}
