import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavigationArea, NavigationPermission, PlatformNavigationComponent } from '@skykick/core';
import { datadogLogs } from '@datadog/browser-logs'
import { BillingTracingService } from './tracing/core/billing.tracing.service';
import { environment } from 'src/environments/environment';
import { SurveyService } from './surveys/survey.service';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Intercom } from '@intercom/messenger-js-sdk';

@Component({
    selector: '[app-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(PlatformNavigationComponent)
    public platformNavigationComponent: PlatformNavigationComponent;
    showNavigation = true;
    langs = ['en', 'sq'];
    loading = false;

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private billingTracingService: BillingTracingService,
        private surveyService: SurveyService,
        private userProvider: AbstractUserProvider,
    ) {
        let browserlang = this.translateService.getBrowserLang();
        if (this.langs.indexOf(browserlang) > -1) {
            this.translateService.setDefaultLang(browserlang);
        } else {
            this.translateService.setDefaultLang('en');
        }

        this.router.events.subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });

        const currentUser = this.userProvider.getCurrentUser();

        // Initialize Hotjar and pass user metadata.
        // Pass false into init if you want to debug hotjar.
        // The debug mode helps to see what events are called in the developer console.
        this.surveyService.init(currentUser.userId, {
            email: currentUser.email,
            partnerId: currentUser.partnerId,
            name: currentUser.fullName,
            partnerName: currentUser.partnerName,
            isTestPartner: currentUser.isTestPartner,
        })

        // Initialize Intercom to support custom notification banners created by managers.
        Intercom({
            app_id: 'itg40p5r',
            email: currentUser.email,
            user_id: currentUser.userId,
            partner_id: currentUser.partnerId,
            name: currentUser.fullName,
            avatar: {
                type: 'avatar',
                image_url: currentUser.picture
            },
            is_admin: currentUser.hasPermission('partnerportaladmin'),
            is_test: currentUser.isTestPartner,
            horizontal_padding: 20,
            vertical_padding: 20
        });
    }

    ngOnInit() {
        // Datadog logging
        datadogLogs.init({
            clientToken: environment.dataDogTracing.clientToken,
            datacenter: 'us',
            forwardErrorsToLogs: true,
            sampleRate: environment.dataDogTracing.sessionSampleRate,
            service: environment.dataDogTracing.serviceName,
            env: environment.dataDogTracing.environment,
            version: environment.dataDogTracing.version
        });
        this.billingTracingService.initializeBillingTracing(environment.dataDogTracing);
    }

    ngAfterViewInit(): void {
        this.platformNavigationComponent.SkNavigationArea = NavigationArea.Billing;
        // Override secondary navigation links
        this.platformNavigationComponent.SubNavigation = [
            {
                displayName: 'Manage Plans',
                url: "/manageplans",
                permissions: NavigationPermission.FullAccess
            },
            {
                displayName: 'Invoices',
                url: "/billing",
                permissions: NavigationPermission.FullAccess
            },
            {
                displayName: 'Billing & Insights <i class="material-icons md-16 ml-50">open_in_new</i>',
                url: this.getPlatformRoutingReportsUrl(),
                permissions: NavigationPermission.FullAccess,
                openInNewTab: true
            }
        ];

    }

    ngOnDestroy(): void {
    }

    useLanguage(lang: string): void {
        this.translateService.setDefaultLang(lang);
    }

    getPlatformRoutingHostname():string {
        var routingDomain = window.location.hostname.includes('.com') ? environment.dotcomRoutingHostname : environment.dotdevRoutingHostname;
        return routingDomain;
    }

    getPlatformRoutingUrlWithPath(route: string): string {
        var routingHostname = this.getPlatformRoutingHostname();

        if (routingHostname.endsWith('/'))
            routingHostname = routingHostname.substring(0, routingHostname.length - 1);
        if (!route.startsWith('/'))
            route = `/${route}`;

        return `${routingHostname}${route}`;
    }

    getPlatformRoutingReportsUrl():string {
        return this.getPlatformRoutingUrlWithPath("/rat/reports");
    }
}
