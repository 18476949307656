import { Injectable } from '@angular/core';
import Hotjar from '@hotjar/browser';
import { environment } from 'src/environments/environment';
import { HotjarEvents } from './hotjar-events';

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    /**
     * @summary Initializes the Hotjar for the application.
     */
    init(userId: string, userInfo: any, isDebugMode: boolean = false): void {
        Hotjar.init(environment.hotjarConfig.apiKey, environment.hotjarConfig.version, { debug: isDebugMode });
        Hotjar.identify(userId, userInfo);
    }

    /**
     * @summary Call hotjar event by event name (HotjarEvents)
     * @param event - Hotjar event.
     */
    event(event: HotjarEvents): void {
        Hotjar.event(event);
    }
}
