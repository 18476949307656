<h3>
    <ng-container *ngIf="(breadcrumbs$ | async) as breadcrumbs">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
            <i class="material-icons align-middle" *ngIf="i !== 0">keyboard_arrow_right</i>
            <a *ngIf="i !== breadcrumbs.length - 1" id="breadcrumb{{i + 1}}" class="text-muted" 
                [routerLink]="breadcrumb.path" 
                [queryParams]="isOpen(breadcrumb)" translate>
                {{ breadcrumb.display }}</a>
            <span *ngIf="i === breadcrumbs.length - 1" translate>{{ breadcrumb.display }}</span>
        </ng-container>
    </ng-container>
</h3>