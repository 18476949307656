import { ProductType } from "../models/PlatformAccountingContract";
import { ProductFilter } from "../models/typings";

export const PRODUCT_TYPES: ProductFilter[] = [
    { name: 'skBilling.all_products', value: null },
    { name: 'skBilling.cloud_backup', value: ProductType.Backup },
    { name: 'skBilling.migration_suites', value: ProductType.Migration },
    { name: 'skBilling.cloud_manager', value: ProductType.CloudManager },
    { name: 'skBilling.security_manager', value: ProductType.SecurityManager },
]




