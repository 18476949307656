<!-- Google Tag Manager (noscript) -->
<noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K895G2Q" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->

<sk-banner-container></sk-banner-container>
<header sk-header></header>
<section class="sk-flex-page">
    <nav sk-platform-navigation [class.d-none]="!showNavigation"></nav>

    <sk-text-spinner *ngIf="loading" class="d-flex align-items-center justify-content-center w-100"></sk-text-spinner>

    <router-outlet></router-outlet>
</section>
