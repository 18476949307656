/**
 * @summary Hotjar events.
 * Events used on SkyKick.Billing
 * These events are created using the Hotjar portal in the 'Targeting' section.
 * Documentation link - https://dev.azure.com/skykick/SkyKick%201/_wiki/wikis/SkyKick%20Engineering%20Wiki/5329/Hotjar-Integration
 */
export enum HotjarEvents{
    DoNotRenewActive = 'do_not_renew_active_popup',
    DoNotRenewNdays = 'do_not_renew_ndays_popup',
}
