import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Invoice, ProductType } from 'src/app/shared/models/PlatformAccountingContract';
import { PlatformPlanDetails } from 'src/app/shared/models/SubscriptionManagementContract';
import { BreadcrumbService } from '../../shared/breadcrumbs/breadcrumb.service';
import { getProductName } from 'src/app/shared/services/utilities';

@Component({
    selector: '[sk-invoice-details]',
    templateUrl: './invoice-details.component.html',
    styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    ProductType = ProductType;
    platformPlanDetail: PlatformPlanDetails;
    invoice: Invoice;
    productName: string;
    showProductName: boolean;

    constructor(
        protected activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.breadcrumbService.push('skBilling.itemized_details', '/billing/invoice/' + params.transactionId + '/details');
        });

        this.activatedRoute.parent.data.pipe(
            takeUntil(this.onDestroy$),
            tap(({ invoice }: { invoice: Invoice }) => {
                this.invoice = invoice;
                this.productName = getProductName(this.invoice.productType)
                this.showProductName = this.invoice.productType === ProductType.CloudManager
                    || this.invoice.productType === ProductType.SecurityManager;
            }),
            switchMap(() => {
                return this.activatedRoute.data;
            }),
            tap(({ platformPlanDetails }: { platformPlanDetails: PlatformPlanDetails[] }) => {
                if (platformPlanDetails) {
                    this.platformPlanDetail = platformPlanDetails.find(item => item.id === this.invoice.relatedSubscriptionId);
                }
            })
        ).subscribe();

    }

    ngOnDestroy(): void {
        this.breadcrumbService.pop();
        this.onDestroy$.next();
    }

}
