import { IEquatable, IComparer } from "./typings";

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Backup\BackupAccountingLineItem.cs
export interface BackupAccountingLineItem {
    id: number;
    backupAccountingId: number;
    backupServiceId: string;
    customerName: string;
    sku: string;
    promoSkuProduct: PromoSkuProduct;
    description: string;
    quantity: number;
    unitPrice: number;
    amount: number;
    endCustomerUnitPrice: number;
    endCustomerAmount: number;
    monthsBeingBilledFor?: number;
    promoDurationInMonths?: number;
    currency: string;
    createdDate: string;
    modifiedDate: string;
    termEndDate?: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Backup\BackupAccountingLineItem.cs
export enum PromoSkuProduct {
    FullMigration = 772370000,
    DataOnlyMigration = 772370001,
    FullBackup = 772370002,
    ExchangeBackup = 772370003,
    SharePointOneDriveBackup = 772370004,
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Backup\BackupCustomerInformation.cs
export interface BackupCustomerInformation {
    companyName: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Backup\BackupDailyUsage.cs
export interface BackupDailyUsage {
    backupDailyUsageId: number;
    date?: string;
    backupServiceId?: string;
    backupMailboxId?: string;
    backupSiteId?: string;
    crmOrderId?: string;
    resourceName: string;
    resourceAddress: string;
    mailboxType: string;
    currentMailboxType: string;
    mailboxBackupEnabled?: number;
    licensedForSharePoint?: number;
    oneDriveBackupEnabled?: number;
    disabledDueToAccess?: string;
    mailboxTypeVerified?: boolean;
    orderId?: number;
    exchangeChangeFromPrevDay?: number;
    exchangeNewLicenses?: number;
    sharePointChangeFromPrevDay?: number;
    sharePointNewLicenses?: number;
    oneDriveChangeFromPrevDay?: number;
    oneDriveNewLicenses?: number;
    mostCurrent: boolean;
    resourceCreated?: string;
    lastSuccessSnapshotDate?: string;
    lastSuccessSnapshotItems?: number;
    lastSuccessSnapshotDataKb?: number;
    lastSnapshotDate?: string;
    lastSnapshotItems?: number;
    lastSnapshotDataKb?: number;
    last24HoursSnapshots?: number;
    last24HoursItems?: number;
    last24HoursDataKb?: number;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Backup\BackupMailboxSnapshotStats.cs
export interface BackupMailboxSnapshotStats {
    backupMailboxId: string;
    last24HoursItems: number;
    last24HoursDataKb: number;
    lastSuccessfulSnapshotDate?: string;
    lastSuccessfulSnapshotItems?: number;
    lastSuccessfulSnapshotDataKb?: number;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Backup\BackupSubscriptionSettings.cs
export interface BackupSubscriptionSettings {
    backupExchangeEnabled: boolean;
    totalExchangeMailboxesEnabled: number;
    backupSharepointEnabled: boolean;
    totalSharepointSitesEnabled: number;
    totalSharepointActiveLicenses: number;
    exchangeSeatLimit?: number;
    exchangeDisabledOn?: string;
    sharepointDisabledOn?: string;
    customerInformation: BackupCustomerInformation;
    state: BackupOrderState;
    totalOneDriveSitesEnabled: number;
    totalIndividualMailboxesEnabled: number;
    totalSharedResourcesEnabled: number;
    individualMailboxesCharged?: number;
    sharedResourcesCharged?: number;
    totalSharepointLicensesCharged?: number;
    seatLimit?: number;
    exchangeLimit?: number;
    sharepointLimit?: number;
    onMicrosoftDomain: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Backup\BackupSubscriptionSettings.cs
export enum BackupOrderState {
    OrderPending = 0,
    Active = 1,
    DisabledDueToNonpayment = 3,
    PurgeRequested = 4,
    Purging = 5,
    Purged = 6,
    Suspended = 7,
    Cancelled = 8,
    Archived = 9,
    Disabled = 10,
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\NetSuite\ItemPrice.cs
export interface ItemPrice {
    currencyId: string;
    lastModified?: string;
    discountPercentage?: number;
    itemId: string;
    itemUnitPrice?: number;
    name: string;
    lastUpdated?: string;
    loadDate: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\NetSuite\LineItem.cs
export interface LineItem {
    amount: number;
    description: string;
    endCustomer: string;
    endDate: string;
    itemName: string;
    orderNumber: string;
    orderType: string;
    promoCode: string;
    prorated: boolean;
    quantity: number;
    rate: number;
    startDate: string;
    subItems: LineItem[];
    skuNumber: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\NetSuite\NetSuiteInvoice.cs
export interface NetSuiteInvoice {
    invoiceNumber: string;
    invoiceTransactionId: string;
    status: Status;
    subTotal: number;
    total: number;
    tax: number;
    amountRemaining: number;
    currencyCode: string;
    lineItems: LineItem[];
    createdDate: string;
    companyName: string;
    dueDate: string;
    relatedSubscriptionId: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\NetSuite\Status.cs
export enum Status {
    Open = 'Open',
    Paid = 'Paid',
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Order\AccountingDetailsRequest.cs
export interface AccountingDetailsRequest {
    partnerId: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Order\BackupAccountingDetails.cs
export interface BackupAccountingDetails {
    pricingPromo: PricingPromo;
    subsequentPricingPromo: PricingPromo;
    termStartDate: string;
    termEndDate: string;
    allowCancel: boolean;
    allowSubsequentPromoChange: boolean;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Order\BaseOrder.cs
export interface BaseOrder {
    orderInternalId: number;
    orderId: string;
    orderCreated: string;
    orderPlaced?: string;
    orderNumber: string;
    orderType: string;
    partnerName: string;
    partnerInternalId: number;
    partnerId: string;
    partnersNetsuiteId?: number;
    partnerContact: string;
    promosDistributorInternalId?: number;
    promosDistributorId?: string;
    promosDistributorName: string;
    partnersDistributorId?: string;
    partnersDistributorName: string;
    partnersDistributorType: string;
    partnersDistributorNetsuiteId?: number;
    customerName: string;
    distiPoNumber: string;
    partnerPoNumber: string;
    pricingPromoInternalId: number;
    pricingPromoCrmId: string;
    pricingPromoName: string;
    pricingPromoType: string;
    promoTypeId?: number;
    pricingPromoCode: string;
    pricingPromoSource: string;
    pricingPromoPartnerPortalTitle: string;
    pricingPromoSpecialId: string;
    pricingPromoBundleType: string;
    currency: string;
    orderStatus: string;
    isIur: boolean;
    isNfr: boolean;
    settlementReportInfo: string;
    isTestOrder: boolean;
    promoBillingTiming: string;
    distributorRelationshipStarted?: string;
    distributorRelationshipEnded?: string;
    distributorStatus: string;
    pricingPromoSourceFriendly: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Order\SalesOrder.cs
export interface SalesOrder {
    orderId: string;
    orderCreated?: string;
    orderType?: OrderType;
    dnsDomain: string;
    onMicrosoftDomain: string;
    partnerId?: string;
    customerId?: string;
    customerName: string;
    promoId?: string;
    currentPromoStartDate?: string;
    subsequentPromoId?: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Order\SalesOrder.cs
export enum OrderType {
    Migration = 0,
    DataOnly = 1,
    Backup = 2,
    CloudManager = 3,
    MigrationsVNext = 4,
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Order\SetSubsequentPromoRequest.cs
export interface SetSubsequentPromoRequest {
    promoId: string;
    partnerId: string;
    actorEmail: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Payment\ApplyPayment.cs
export interface ApplyPayment {
    partnerId: string;
    invoiceNumber: string;
    invoiceTransactionId: string;
    customerProfileId: string;
    paymentProfileId: string;
    paymentAmount: number;
    comments: string;
    autoPaySettings?: AutoPay;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Payment\AutoPay.cs
export interface AutoPay {
    enabled: boolean;
    subscriptionId: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Payment\ClientInfo.cs
export interface ClientInfo {
    loginId: string;
    publicClientKey: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Payment\CreditCard.cs
export interface CreditCard {
    companyName: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    address: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    countryCode?: number;
    countryName: string;
    cardNumber: string;
    expMonth?: number;
    expYear?: number;
    secCode: string;
    opaqueDataValue: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Payment\CreditCardPaymentForInvoice.cs
export interface CreditCardPaymentForInvoice {
    partnerId: string;
    invoiceNumber: string;
    invoiceTransactionId: string;
    customerProfileId: string;
    paymentProfileId: string;
    amount: number;
    memo: string;
    paymentNote: string;
    creditCardTransactionId: string;
    creditCardAuthorizationCode: string;
    creditCardName: string;
    creditCardNumber: string;
    creditCardExpirationDate: string;
    creditCardSettlementDate?: string;
    creditCardBatchId: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Payment\CustomerPaymentProfile.cs
export interface CustomerPaymentProfile {
    profileId: string;
    paymentProfileId: string;
    isDefault: boolean;
    cardType: string;
    cardNumber: string;
    expirationDate: string;
    firstName: string;
    lastName: string;
    company: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\MigrationLicenseChargeAuthority.cs
export enum MigrationLicenseChargeAuthority {
    Source = 772370000,
    Destination = 772370001,
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\PricingPromo.cs
export interface MigrationOptions {
    excludePublicFolderCharges: boolean;
    excludeSharedMailboxCharges: boolean;
    bundledWithBackup: boolean;
    migrationLicenseChargeAuthority: MigrationLicenseChargeAuthority;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\PricingPromo.cs
export interface BackupOrderConfiguration {
    promoDurationInMonths: number;
    promoCommitInYears: number;
    allowExchangeIndividualBackup: boolean;
    allowExchangeSharedBackup: boolean;
    allowOneDriveBackup: boolean;
    allowSharePointBackup: boolean;
    allowGroupsBackup: boolean;
    manageExchangeIndividual: boolean;
    manageExchangeShared: boolean;
    manageOneDrive: boolean;
    manageSharePoint: boolean;
    manageGroups: boolean;
    requiresByoa: boolean;
    excludeExchangeSharedResourcesCharges: boolean;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\PricingPromo.cs
export interface PricingPromo {
    id: string;
    partnerDefaultPromo: boolean;
    promoSource?: PromoSource;
    orderType?: OrderType;
    internalName: string;
    code: string;
    specialPromoId: string;
    distributorId?: string;
    distributorName: string;
    partnerPortalTitle: string;
    partnerPortalDescription: string;
    learnMoreUrl: string;
    isIur: boolean;
    isOneTimeUse: boolean;
    isSelfSelectable: boolean;
    subsequentPromoId?: string;
    migrationOptions: MigrationOptions;
    backupOptions: BackupOrderConfiguration;
    requiresCreditCard: boolean;
    promoCurrencyCode: string;
    skuProduct?: number;
    skuProductCode: string;
    skuProductName: string;
    secondSkuProduct?: number;
    secondSkuProductCode: string;
    secondSkuProductName: string;
    bundleType: string;
    pricingUnit: string;
    seatUnitPrice?: number;
    exchangeLicenseUnitPrice?: number;
    sharePointLicenseUnitPrice?: number;
    sharedResourceLicenseUnitPrice?: number;
    initialMonthsFree?: number;
    description: string;
    pricingMethod: string;
    invoiceSku: string;
    secondaryInvoiceSku: string;
    promoTypeId?: number;
    promoType: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\PricingPromo.cs
export enum PromoSource {
    Unknown = 0,
    Traditional = 1,
    Marketplace = 2,
    APIDirect = 3,
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\PromosByOrderIdRequest.cs
export interface PromosByOrderIdRequest {
    promoSource: PromoSource;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\PromosRequest.cs
export interface PromosRequest {
    orderType: OrderType;
    promoSource: PromoSource;
    partnerId: string;
    domainToBackup: string;
    domainsToBackup: string[];
    distributorId?: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Promo\SubsequentPromoRequest.cs
export interface SubsequentPromosRequest {
    orderType: OrderType;
    promoSource: PromoSource;
    partnerId: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Currency.cs
export interface Currency extends IEquatable<Currency> {
    id: number;
    currencySymbol: string;
    name: string;
    symbol: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Distributor.cs
export interface Distributor {
    code: string;
    currencyCode: string;
    distributorId: string;
    distributorType: string;
    marketplaceEnabled: boolean;
    name: string;
    promos: PricingPromo[];
    requirePoForOrderPlacement: boolean;
    skyKickDirectEnabled: boolean;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Distributor.cs
export interface DistributorComparer extends IComparer<Distributor> {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\DistributorTypes.cs
export interface DistributorTypes {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Invoice.cs
export interface Invoice {
    amountApplied?: number;
    amountOutstanding?: number;
    companyName: string;
    createdDate?: string;
    currencyCode: string;
    currencySymbol: string;
    currencyRate?: number;
    daysPastDue?: number;
    dueDate?: string;
    invoiceDate?: string;
    invoiceNumber: string;
    lineItems: LineItem[];
    memo: string;
    partnerId: string;
    partnerName: string;
    productType: ProductType;
    recordType: string;
    reference: string;
    relatedSubscriptionId: string;
    salesOrderId?: number;
    status: string;
    subtotal?: number;
    tax?: number;
    total?: number;
    totalDiscount?: number;
    transactionId: number;
    updatedDateUtc: string;
    paidDate?: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\MigrationBillingStatuses.cs
export interface MigrationBillingStatuses {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\NetSuiteCustomer.cs
export interface NetSuiteCustomer {
    companyName: string;
    emailAddress: string;
    id: number;
    partnerId: string;
    isInvoicingEnabled: boolean;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\PagedList.cs
export interface PagedList<T> {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    hasPrevious: boolean;
    hasNext: boolean;
    items: T[];
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Partner.cs
export interface Partner {
    partnerId: string;
    name: string;
    partnerDomainName: string;
    distributors: Distributor[];
    specialPromos: PricingPromo[];
    defaultMigrationPricingId?: string;
    defaultDataOnlyPricingId?: string;
    defaultBackupPricingId?: string;
    defaultCloudManagerPricingId?: string;
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\ProductAccess.cs
export interface ProductAccess {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Products.cs
export interface Products {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\TransactionTypes.cs
export interface TransactionTypes {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\ICurrencyClient.cs
export interface ICurrencyClient {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\IInvoiceClient.cs
export interface IInvoiceClient {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\INetSuiteAccountingClient.cs
export interface INetSuiteAccountingClient {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\IPartnerClient.cs
export interface IPartnerClient {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\IPaymentClient.cs
export interface IPaymentClient {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\IProductClient.cs
export interface IProductClient {
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Invoices\InvoiceRequestFilter.cs
export interface InvoiceRequestFilter {
    pageNumber: number;
    pageSize: number;
    productTypes: ProductType[];
}

// ..\..\SkyKick.Platform.Accounting\SkyKick.Accounting\Accounting.Contract\Models\Invoices\ProductType.cs
export enum ProductType {
    Unknown = 0,
    Backup = 1,
    Migration = 2,
    CloudManager = 3,
    SecurityManager = 4,
}
