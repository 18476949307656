export interface HotjarConfiguration {
    version: number;
    apiKey: number;
}

/**
 * @summary Default Hotjar configuration
 */
export const defaultHotjarConfiguration: HotjarConfiguration = {
    version: 7,
    apiKey: 732514,
}
/**
 * @summary Can be used in the future on dev envs if we don't want to have Hotjar on them.
 * Disable Hotjar for environment
 */
export const fakeHotjarConfiguration: HotjarConfiguration = {
    version: 6,
    apiKey: 0,
}
