<div *ngIf="displayFooter" class="sk-footer-content">
    <ng-container [ngSwitch]="controllerName">

        <ng-container *ngSwitchCase="ControllerNames.InvoicePaymentComponent">
            <button *ngIf="isPrimaryVisible" id="primaryButton" sk-spinner-button class="me-3" [color]="'primary'" (click)="submitPrimaryButton()" [isSpinning]="isPrimaryBusy" 
                [disabled]="isPrimaryBusy || isPrimaryDisabled || isImpersonating()">{{ 'skBilling.pay_invoice' | translate }}
            </button>
            <button id="secondaryButton" sk-spinner-button class="me-3 sk-btn-combo" [color]="'tertiary'" (click)="submitSecondaryButton()" [isSpinning]="isSecondaryBusy" 
                [disabled]="isSecondaryBusy || isSecondaryDisabled">
                <i class="material-icons md-22 md-blue">file_download</i>
                <span>{{ 'skBilling.download' | translate }}</span>
            </button>

            <div *ngIf="isImpersonating()" style="display:flex;color:#f08800">
                <i class="material-icons md-22 icon-warning">error</i>
                <div>Pay Invoice button is disabled for impersonator.</div>
            </div>
        </ng-container>
    </ng-container>
</div>