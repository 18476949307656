import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BannerModule, ButtonsModule, HeaderModule, HubspotFormsModule, InputModule, LayoutModule, NavigationModule, ModalsModule, SortingModule, StatusSpinnerModule, UtilityModule, FeedbackModule } from '@skykick/core';
import { AbstractUserProvider, AuthModule } from '@skykick/platform-identity-auth-auth0-angular';
import { AppConfigService } from './app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { BillingComponent } from './billing/billing.component';
import { InvoiceDetailsComponent } from './invoice/invoice-details/invoice-details.component';
import { InvoicePaymentComponent } from './invoice/invoice-payment/invoice-payment.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { PlatformPlanComponent } from './platform-plan/platform-plan.component';
import { SubscriptionComponent } from './platform-plan/subscription/subscription.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs/breadcrumbs.component';
import { CreditCardModule } from './shared/creditcard/creditcard.module';
import { BillingContactComponent } from './shared/modals/billing-contact/billing-contact.component';
import { PageFooterModule } from './shared/page-footer/page-footer.module';
import { PluralTranslatePipe } from './shared/pipes/plural-translate.pipe';
import { ProductFilterPipe } from './shared/pipes/product-filter.pipe';
import { BillingTracingModule } from './tracing/billing.tracing.module';

function getPlatformRoutingHostname() {
    const routingDomain = window.location.hostname.includes('.com') ? environment.dotcomRoutingHostname : environment.dotdevRoutingHostname;
    return routingDomain;
}

export function TranslationLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function LanguageLoader(translate: TranslateService, cookieService: CookieService): () => Promise<void> {
    let promise = new Promise<void>(resolve => {
        const cookieLang = cookieService.get('skLanguageCode');
        const browserLang = cookieLang || translate.getBrowserLang();
        const lang = browserLang.match(/en|ja|sq|de/) ? browserLang : 'en';
        translate.use(lang);
        resolve();
    });
    return async () => promise;
}

@NgModule({ 
    declarations: [
        AppComponent,
        InvoicesComponent,
        BillingComponent,
        InvoicePaymentComponent,
        BreadcrumbsComponent,
        PluralTranslatePipe,
        PlatformPlanComponent,
        SubscriptionComponent,
        BillingContactComponent,
        InvoiceDetailsComponent,
        InvoiceComponent,
        ProductFilterPipe
    ],
    bootstrap: [AppComponent],
    imports: [
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgbModule,
        CommonModule,
        BrowserAnimationsModule,
        NavigationModule,
        HeaderModule,
        ButtonsModule,
        InputModule,
        LayoutModule,
        SortingModule,
        FeedbackModule,
        AuthModule.forRoot({
            LicenseAssignmentServiceBaseUrl: environment.apis.licensesAssignmentBaseAddress + '/licenseassignment/api/v1',
            SewrSystemDomain: getPlatformRoutingHostname()
        }),
        ToastrModule.forRoot(),
        PageFooterModule,
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] }
        }),
        UtilityModule.forRoot({ sewrSystemDomain: getPlatformRoutingHostname() }),
        CreditCardModule,
        StatusSpinnerModule,
        HubspotFormsModule,
        BannerModule,
        ModalsModule,
        BillingTracingModule
    ], 
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: LanguageLoader,
            multi: true,
            deps: [TranslateService, CookieService]
        },
        CookieService,
        { provide: AbstractUserProvider, useExisting: environment.userProvider },
        { provide: 'Window', useValue: window },
        AppConfigService,
        AuthService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfigService: AppConfigService, authService: AuthService) => {
                return () => {
                    authService.run();
                };
            },
            deps: [AppConfigService, AuthService],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] 
})
export class AppModule { }
