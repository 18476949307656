<div class="pb-200 px-200 max-width-md min-width-md">
    <div *ngIf="invoice">
        <div class="card">
            <div class="card-block sk-card-header d-flex justify-content-between align-items-center">
                <h4 translate>skBilling.invoicedetailpage.total_charges</h4>
                <a id="viewDetailsLink" [routerLink]="['/', 'billing', 'invoice', invoice.transactionId, 'details']" translate>skBilling.invoicedetailpage.view_itemized_details</a>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between">
                    <div translate>skBilling.invoicedetailpage.subscription</div>
                    <h3 id="subtotal">{{invoice.subtotal | currency: invoice.currencyCode}}</h3>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                    <div translate>skBilling.invoicedetailpage.taxes_&_fees</div>
                    <h3 id="tax">{{invoice.tax | currency: invoice.currencyCode}}</h3>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                    <h3 translate>skBilling.invoicedetailpage.subscription_total
                        <i class="material-icons md-20 icon-muted align-middle" ngbPopover="{{'skBilling.invoicedetailpage.total_warning' | translate}}" triggers="mouseenter:mouseleave" placement="right">help_outline</i>
                    </h3>
                    <h3 id="total">{{displayTotal() | currency: invoice.currencyCode}}</h3>
                </li>
            </ul>
        </div>

        <sk-customer-payment-profiles 
            *ngIf="invoice.currencyCode === 'USD' && invoice.status !== Status.Paid" ></sk-customer-payment-profiles>

        <div *ngIf="displayAutoPay">
            <div class="form-check mt-200">
                <input type="checkbox" id="enableAutopay" class="form-check-input" [(ngModel)]="accountingSubscription.autoPayEnabled">
                <label for="enableAutopay" class="form-check-label font-weight-medium" translate>skBilling.autopay</label>
            </div>
            <div class="text-muted pl-200 mt-25" [innerHTML]="'skBilling.pay_future_invoices'| translate:{ productplan: getProductPlanText(invoice.productType) }"></div>    
        </div>

    </div>
    <sk-text-spinner *ngIf="!invoice" message="{{'skBilling.invoicedetailpage.fetching_invoice_details' | translate}}"></sk-text-spinner>
</div>
