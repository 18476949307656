<div class="sk-page-header header-light">
    <div class="sk-navbar-primary --secondary-none">
        <div class="d-flex align-items-center min-w-0">
            <div class="d-flex min-w-0">
                <div class="min-w-0 mb-50">
                    <sk-breadcrumbs></sk-breadcrumbs>                    
                </div>
            </div>
        </div>

        <!-- <ul sk-page-header-nav class="sk-tabs-lg d-flex">
            <li *ngFor="let page of Pages" sk-page-header-nav-item [link]="page.Route" [label]="page.Name"></li>
        </ul> -->
    </div>
</div>

<div class="sk-page-content">

    <router-outlet></router-outlet>

    <!-- PANEL RIGHT -->
    <!-- <div sk-panel></div> -->
    <aside class="sk-panel-right panel-md panel-hidden">
        <div class="sk-panel-header --heading-only">
            <div class="sk-header-row">

                <button type="button" class="btn sk-btn-icon pl-0 --d-none"><i
                        class="material-icons md-22">arrow_back</i></button>

                <h3>Heading</h3>
                <button type="button" id="close-right" class="btn sk-btn-icon close"><i
                        class="material-icons md-22">close</i></button>
            </div>
            <!-- /.sk-header-row -->

            <div class="sk-navbar-panel --d-none">
                <ul class="nav sk-tabs-panel">
                    <li class="nav-item">
                        <a href="#" class="nav-link active" data-original-title="" title="">First Ipsum</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link " data-original-title="" title="">Second Dolor</a>
                    </li>
                </ul>
            </div>
            <!-- /.sk-navbar-panel -->

            <!-- <div class="sk-panel-search --with-nav --d-none">
                <div class="sk-input-icon w-100">
                    <input type="text" class="form-control d-grid col-12" placeholder="Search"><i class="material-icons md-18">search</i>
                </div>
            </div> -->
            <!-- /.sk-panel-search -->

        </div>
        <!-- /.sk-panel-header -->



        <div class="sk-panel-content">
            <div class="sk-panel-search with-scroll --d-none">
                <div class="sk-input-icon w-100">
                    <input type="text" class="form-control d-grid col-12" placeholder="Search"><i
                        class="material-icons md-18">search</i>
                </div>
            </div>

            <div class="sk-panel-scroll px-150">
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                    Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a,
                    pede. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis
                    eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere
                    a, pede.</p>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                    Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a,
                    pede. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis
                    eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere
                    a, pede.</p>
            </div>

        </div>
        <!-- /.sk-panel-content -->

        <div class="sk-panel-footer">
            <button type="button" class="btn btn-secondary">Actionerat</button>
        </div>
        <!-- /.sk-panel-footer -->

    </aside>

</div>

<sk-action-buttons></sk-action-buttons>
