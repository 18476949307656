import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import Breadcrumb from './breadcrumb';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    private breadcrumbs: Breadcrumb[] = [];
    private breadcrumbsSubject: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject(this.breadcrumbs);

    public breadcrumbs$: Observable<Breadcrumb[]> = this.breadcrumbsSubject.asObservable();

    constructor() { }

    public push(displayName: string, path: string) {
        this.breadcrumbs.push({
            display: displayName,
            path
        });

        this.breadcrumbsSubject.next(this.breadcrumbs);
    }

    public pop() {
        this.breadcrumbs.pop();

        this.breadcrumbsSubject.next(this.breadcrumbs);
    }

    public get depth(): number {
        return this.breadcrumbs.length;
    }
}
