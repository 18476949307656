import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SkykickPlatformPage, TaskManagerService } from '@skykick/core';
import { BreadcrumbService } from '../shared/breadcrumbs/breadcrumb.service';

@Component({
    selector: 'main[sk-plaform-plan]',
    templateUrl: './platform-plan.component.html',
    styleUrls: ['./platform-plan.component.scss']
})
export class PlatformPlanComponent extends SkykickPlatformPage implements OnInit, OnDestroy {

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected taskManagerService: TaskManagerService,
        private breadcrumbService: BreadcrumbService
    ) { 
        super(activatedRoute, taskManagerService);
    }

    ngOnInit(): void {
        this.breadcrumbService.push('platform_plan_page.manage_plans', '/manageplans/subscription');
    }

    ngOnDestroy(): void {
        this.breadcrumbService.pop();
    }

}
