import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from '@skykick/platform-identity-auth-auth0-angular';
import { ButtonsModule } from '@skykick/core';


@NgModule({
    declarations: [
        ActionButtonsComponent
    ],
    imports: [
        AuthModule,
        CommonModule,
        ButtonsModule,
        TranslateModule.forChild({
            extend: true
        })
    ],
    exports: [
        ActionButtonsComponent
    ]
})
export class PageFooterModule { }
