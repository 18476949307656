import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';

export const AuthGuard: CanActivateFn = () => {
  const isCustomerUser = inject(AuthService).isCustomerUser();
    if (isCustomerUser) {
      inject(Router).navigate(['/login']);
      return false;
    }

    //If you are not a customer user you are allowed through
    return true;
};