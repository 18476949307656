import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import { NotFoundComponent } from '@skykick/core';

import { BillingComponent } from './billing/billing.component';
import {
  InvoiceDetailsComponent,
} from './invoice/invoice-details/invoice-details.component';
import {
  InvoicePaymentComponent,
} from './invoice/invoice-payment/invoice-payment.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { CompanyInfoResolver } from './platform-plan/company-info.resolver';
import {
  DistributorInfoResolver,
} from './platform-plan/distributor-info.resolver';
import { PlatformPlanComponent } from './platform-plan/platform-plan.component';
import {
  SubscriptionDetailsResolver,
} from './platform-plan/subscription-details.resolver';
import {
  SubscriptionComponent,
} from './platform-plan/subscription/subscription.component';
import { ControllerNames } from './shared/models/ControllerNames';
import {
  ConnectWisePartnerResolver,
} from './shared/resolvers/connect-wise-partner.resolver';
import { InvoiceResolver } from './shared/resolvers/invoice.resolver';
import {
  NetSuiteCustomerResolver,
} from './shared/resolvers/netsuite-customer.resolver';
import { AuthGuard } from './utilities/AuthGuard';
import { environment } from 'src/environments/environment';
import { LocalUserProviderService } from './services/localuser-provider.service';
import { DistributorInfoLocalResolver } from './platform-plan/distributor-info.local.resolver';
import { CompanyInfoLocalResolver } from './platform-plan/company-info.local.resolver';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'billing',
        pathMatch: 'full'
    },
    {
        path: 'billing',
        component: BillingComponent,
        canActivate: [AuthGuard],
        data: {
            name: 'Billing',
            type: 'primary',
            hasFooterActions: false
        },
        children: [
            {
                path: '',
                redirectTo: 'invoices',
                pathMatch: 'prefix'
            },
            {
                path: 'invoices',
                component: InvoicesComponent,
                data: {
                    name: 'Invoices',
                    type: 'secondary',
                    hasFooterActions: false,
                    controllerName: ControllerNames.InvoicesComponent
                },
                resolve: {
                    netSuiteCustomer: NetSuiteCustomerResolver,
                    connectWisePartner: ConnectWisePartnerResolver
                }
            },
            {
                path: 'invoice/:transactionId',
                component: InvoiceComponent,
                data: {
                    hasFooterActions: false
                },
                resolve: {
                    invoice: InvoiceResolver
                },
                children: [
                    {
                        path: '',
                        component: InvoicePaymentComponent,
                        data: {
                            name: 'Invoice Payment',
                            type: 'secondary',
                            hasFooterActions: true,
                            controllerName: ControllerNames.InvoicePaymentComponent
                        }
                    },
                    {
                        path: 'details',
                        component: InvoiceDetailsComponent,
                        resolve: {
                            platformPlanDetails: SubscriptionDetailsResolver
                        },
                        data: {
                            name: 'Invoice Details',
                            type: 'secondary',
                            hasFooterActions: false,
                            controllerName: ControllerNames.InvoiceDetailsComponent
                        }
                    }
                ]
            },
        ]
    },
    {
        path: 'manageplans',
        component: PlatformPlanComponent,
        canActivate: [AuthGuard],
        data: {
            name: 'Manage Plans',
            type: 'primary',
            hasFooterActions: false
        },
        children: [
            {
                path: '',
                redirectTo: 'subscription',
                pathMatch: 'prefix'
            },
            {
                path: 'subscription',
                component: SubscriptionComponent,
                resolve: {
                    companyInfo: environment.userProvider === LocalUserProviderService ? CompanyInfoLocalResolver : CompanyInfoResolver,
                    distributorInfo: environment.userProvider === LocalUserProviderService ? DistributorInfoLocalResolver : DistributorInfoResolver,
                    platformPlanDetails: SubscriptionDetailsResolver
                },
                data: {
                    name: 'Subscription',
                    type: 'secondary',
                    hasFooterActions: false,
                    controllerName: ControllerNames.SubscriptionComponent
                }
            }
        ]
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
