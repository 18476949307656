import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import Breadcrumb from '../breadcrumb';
import { BreadcrumbService } from '../breadcrumb.service';

@Component({
    selector: 'sk-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    breadcrumbs$: Observable<Breadcrumb[]>;
    fromInvoiceDueTab: boolean = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        protected activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['is'] == 'open') {
                this.fromInvoiceDueTab = true;
            }
        });
    }

    isOpen(breadcrumb: Breadcrumb) {
        if (this.fromInvoiceDueTab && breadcrumb.path.indexOf('/billing/invoices') === -1) {
            return { is: 'open' };
        }
        return false;
    }
}
