import { Injectable } from '@angular/core';

import {
  Observable,
  of,
} from 'rxjs';

import {
  AbstractUserProvider,
  ISkyKickPartnerPortalUser,
} from '@skykick/platform-identity-auth-auth0-angular';
import {
  ILicenseAssignment,
} from '@skykick/platform-identity-auth-auth0-angular/lib/user-provider/iskykick-partner-portal-user-license-info';

@Injectable({
  providedIn: 'root'
})
export class GodAdminSuperUser implements ISkyKickPartnerPortalUser {
  isCustomer = false;

  extendedProfile: { [property: string]: any; } = {};

  id = '123';
  partnerId = 'e439b422-0b56-e711-8107-e0071b71f9b1';
  fullName = 'Local User';
  firstName = 'Local';
  lastName = 'User';
  picture = 'https://design.skykick.com/img/svg/skykick-badge.svg';
  email = 'pi.automation@skykick.com';
  userId = 'e739b422-0b56-e711-8107-e0071b71f9b1';
  assignedLicenses: Promise<ILicenseAssignment[]>;
  partnerName: string = "PI Automation";
  isTestPartner = true;

  hasLicenseForProductAndLicenseType(productId: string, licenseTypeId: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  hasPermission(permission: string): boolean {
    return true;
  }

  hasPermissionObs(permission: string, audienceName?: string): Observable<boolean> {
    return of(true);
  }

  hasLicense(productId: string): Promise<boolean> {
    return Promise.resolve(true);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocalUserProviderService extends AbstractUserProvider {
  private localUser: ISkyKickPartnerPortalUser = new GodAdminSuperUser();

  constructor() {
    super();
  }

  getCurrentUser(): ISkyKickPartnerPortalUser {
    return this.localUser;
  }

  hasLoginExpired(): boolean {
    return false;
  }
}
