import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private appConfig: AppConfig;

    constructor(private http: HttpClient) { }

    loadAppConfig() {
        return this.http.get<AppConfig>(environment.apis.hostname + '/v2/Configuration/UI/')
            .toPromise()
            .then(data => {
                this.appConfig = data;
            });
    }

    getConfig() {
        return this.appConfig;
    }

    getRedirectUrl(): string {
        return this.appConfig.loginRedirectUrl;
    }
}

export class AppConfig {
    loginRedirectUrl: string;
}