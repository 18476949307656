import { Inject, Injectable } from "@angular/core";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(
        private userProvider: AbstractUserProvider,
        @Inject("Window") private window: Window
    ) {}

    run() {
        return new Promise<void>((resolve, reject) => {
            const portalPageThatWillRedirectToLoginPage =
                this.getLoginRedirect();
            try {
                const user = this.userProvider.getCurrentUser();
                // If user does not have Billing access permission they are redirected to the home page.
                if (!user.hasPermission("Billing_Full_Access")) {
                    this.window.location.href = this.getHomePageUrl();
                    reject(
                        new Error(
                            "User does not have billing permission to access this area"
                        )
                    );
                }
                resolve();
            } catch (e) {
                // The currently logged in user doesn't exist or their session has expired
                this.window.location.href =
                    portalPageThatWillRedirectToLoginPage;
                reject(new Error("User is not logged into the portal"));
            }
        });
    }

    public isCustomerUser(): boolean {
        return this.userProvider.getCurrentUser().isCustomer;
    }

    private getLoginRedirect(): string {
        const fullUrl = new URL(this.window.location.href);
        const baseUrl = (fullUrl.origin + fullUrl.pathname).toLocaleLowerCase();
        const returnTo = fullUrl.searchParams.get("return_to") ?? baseUrl;
        const encodedReturnTo = encodeURIComponent(returnTo);
        let loginRedirectUrl = this.getLoginUrlForEnv(baseUrl);

        return `${loginRedirectUrl}?return_to=${encodedReturnTo}`;
    }

    private getLoginUrlForEnv(url): string {
        if (url.includes("localhost")) {
            return "http://localhost:15200/login";
        } else if (url.includes("dev0")) {
            return "https://sk-dev0-auth.skykick.com/login";
        } else if (url.includes("-dev1")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-dev2")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-dev3")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-dev4")) {
            return "https://sk-dev4-auth.skykick.com/login";
        } else if (url.includes("-dev5")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-dev6")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-dev7")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-dev8")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-dev9")) {
            return "https://sk-dev-auth.skykick.com/login";
        } else if (url.includes("-qa2")) {
            return "https://sk-qa2-auth.skykick.com/login";
        } else if (url.includes("-qa")) {
            return "https://sk-qa-auth.skykick.com/login";
        } else if (url.includes("-int")) {
            return "https://sk-int-auth.skykick.com/login";
        } else {
            return "https://auth.skykick.com/login";
        }
    }

    private getHomePageUrl(): string {
        const currentUrl = this.window.location.href.toLocaleLowerCase();

        let homePageUrl = "https://portal.skykick.com";

        // intentionally hard-coded and bad - see warning above.
        if (currentUrl.includes("localhost")) {
            homePageUrl = "https://localhost:4000/";
        } else if (
            currentUrl.includes("dev0") ||
            currentUrl.includes("sandbox")
        ) {
            homePageUrl = "https://sk-dev0-portal.skykick.com";
        } else if (currentUrl.includes("dev4")) {
            homePageUrl = "https://sk-dev4-portal.skykick.com";
        } else if (currentUrl.includes("dev")) {
            homePageUrl = "https://sk-dev9-portal.skykick.com";
        } else if (currentUrl.includes("-qa")) {
            homePageUrl = "https://sk-qa2-portal.skykick.com";
        }
        return homePageUrl;
    }
}
