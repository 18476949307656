<div class="sk-page-header header-light">
    <div class="sk-navbar-primary --secondary-none">
        <div class="d-flex align-items-center min-w-0">
            <div class="d-flex min-w-0">
                <div class="min-w-0 mb-50">
                    <sk-breadcrumbs></sk-breadcrumbs>                    
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sk-page-content">

    <router-outlet></router-outlet>

</div>

<sk-action-buttons></sk-action-buttons>
