import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplyPayment, ClientInfo, CreditCard, CustomerPaymentProfile, Invoice, NetSuiteCustomer, PagedList, Status } from 'src/app/shared/models/PlatformAccountingContract';
import { PlatformPlanDetails, Subscription } from 'src/app/shared/models/SubscriptionManagementContract';
import { environment } from 'src/environments/environment';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ProductFilter } from '../models/typings';

@Injectable({
    providedIn: 'root'
})
export class BillingResourcesService {

    private handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Client Side Error: ', errorResponse.error.message);
        } else {
            console.error('Server Side Error: ', errorResponse);
        }
        console.log('There is a problem with the service. We are notified & working on it. Please try again later.');
        return throwError(errorResponse);
    }

    // Converts Base64 to byte array
    private base64ToArrayBuffer(data) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };
    
    constructor(
        private http: HttpClient,
        private abstractUserProvider: AbstractUserProvider
    ) { }

    getInvoicesDue(): Observable<Invoice[]> {
        return this.http.get<Invoice[]>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/invoice/openinvoices/${this.abstractUserProvider.getCurrentUser().partnerId}`)
          .pipe(catchError(this.handleError));
      }

    getInvoiceDetail(transactionId: string): Observable<Invoice> {
        return this.http.get<Invoice>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/invoice/${transactionId}`)
            .pipe(catchError(this.handleError));
    }

    getInvoicePdf(transactionId: number): Observable<any> {
        const url = `${environment.apis.accountingApimSuffix}${environment.apis.prefix}/invoice/${transactionId}/download`;
        return this.http.get(url, { responseType: 'json' as 'text' })
            .pipe(
                catchError(this.handleError),
                map(res => {
                    return this.base64ToArrayBuffer(res);
                })
            );
    }
    
    getPaymentProfiles(): Observable<CustomerPaymentProfile[]> {
        return this.http.get<CustomerPaymentProfile[]>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/payment/profiles/${this.abstractUserProvider.getCurrentUser().partnerId}`)
            .pipe(
                catchError(this.handleError),
                map(res => {
                    res.forEach(item => {
                        item.cardNumber = item.cardNumber.replace(/\D/g,'');
                    });
                    return res;
                })
            );
    }

    addCreditCard(payload: CreditCard): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/payment/profile/${this.abstractUserProvider.getCurrentUser().partnerId}/secureAddCard`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    applyPayment(payload: ApplyPayment): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/payment/apply`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    getPaymentClientInfo() {
        return this.http.get<ClientInfo>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/payment/clientinfo`, { withCredentials: true })
            .pipe(catchError(this.handleError));
    }

    getSubscriptions(): Observable<Subscription[]> {
        return this.http.get<Subscription[]>(`${environment.apis.subscriptionManagementHostname}${environment.apis.prefix}/subscriptionmanagement/`, { withCredentials: true })
            .pipe(catchError(this.handleError));
    }

    patchAutoPay(subscription: Subscription): Observable<void> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch<void>(`${environment.apis.subscriptionManagementHostname}${environment.apis.prefix}/subscriptionmanagement/${subscription.id}/autoPay/${subscription.autoPayEnabled}`, null, { headers: headers, withCredentials: true })
            .pipe(catchError(this.handleError));
    }

    getPlatformPlanDetails(): Observable<PlatformPlanDetails[]> {
        return this.http.get<PlatformPlanDetails[]>(`${environment.apis.subscriptionManagementHostname}${environment.apis.prefix}/subscriptionmanagement/details/${this.abstractUserProvider.getCurrentUser().partnerId}`, { withCredentials: true })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        return of(null);
                    }
                })
            );
    }

    getSubscriptionDetails(): Observable<PlatformPlanDetails[]> {
        return this.http.get<PlatformPlanDetails[]>(`${environment.apis.subscriptionManagementHostname}${environment.apis.prefix}/subscriptionmanagement/platformplandetails`, { withCredentials: true })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        return of(null);
                    }
                }),
            );
    }

    getCMNetSuiteInvoice(): Observable<Invoice> {
        return this.http.get<Invoice>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/invoice/cm/${this.abstractUserProvider.getCurrentUser().partnerId}/latest`, { withCredentials: true });
    }

    getPartnerCompanyInfo(): Observable<any> {
        return this.http.get<any>(`${environment.apis.papi}/partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/companyinfo`, { withCredentials: true });
    }

    getDistributorInfo(): Observable<any> {
        return this.http.get<any>(`${environment.apis.papi}/common/distributorinfo`, { withCredentials: true });
    }

    getConnectWisePartner(): Observable<any> {
        return this.http.get<any>(`${environment.apis.papi}/connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}`, { withCredentials: true })
            .pipe(catchError(this.handleError));
    }

    getNetSuiteCustomer() {
        return this.http.get<NetSuiteCustomer>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/partner/${this.abstractUserProvider.getCurrentUser().partnerId}`, { withCredentials: true })
            .pipe(catchError(this.handleError));
    }

    patchBillingEmail(emailAddress: String): Observable<void> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch<void>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/partner/${this.abstractUserProvider.getCurrentUser().partnerId}/email/billing/${emailAddress}`, null, { headers: headers, withCredentials: true })
            .pipe(catchError(this.handleError));
    }

    getInvoices(status: Status, pageSize: number, pageNumber: number, productTypes: ProductFilter): Observable<PagedList<Invoice>> {
        let productTypesString = '';
        if (productTypes.value) {
            productTypesString = '&productTypes=' + productTypes.value;
        }
        return this.http.get<PagedList<Invoice>>(`${environment.apis.accountingApimSuffix}${environment.apis.prefix}/invoice/${status}/${this.abstractUserProvider.getCurrentUser().partnerId}?PageSize=${pageSize}&PageNumber=${pageNumber}${productTypesString}`)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    cancelSubscription(subscriptionId: string): Observable<void> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch<void>(`${environment.apis.subscriptionManagementHostname}${environment.apis.prefix}/subscriptionmanagement/${subscriptionId}/state/cancelled`, null, { headers: headers, withCredentials: true }).pipe(
            catchError(this.handleError)
        );
    }

    setRenewal(subscriptionId: string, renew: boolean): Observable<void> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.patch<void>(`${environment.apis.subscriptionManagementHostname}${environment.apis.prefix}/subscriptionmanagement/${subscriptionId}/renewal/${renew}`, null, { headers: headers, withCredentials: true }).pipe(
            catchError(this.handleError)
        );
    }

}
