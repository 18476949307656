import { Pipe, PipeTransform } from '@angular/core';
import { Invoice, ProductType } from '../models/PlatformAccountingContract';

@Pipe({
    name: 'productFilter'
})
export class ProductFilterPipe implements PipeTransform {

    transform(invoices: Invoice[], productType: ProductType): Invoice[] {
        return invoices.filter(invoice => {
            if (!productType) {
                return invoice;
            }
            return invoice.productType === productType;
        });
    }

}
