<div class="sk-navbar-tertiary --indented" id="tertiaryTabs">
    <ul class="nav sk-tabs-base flex-nowrap text-truncate">
        <li *ngFor="let item of invoiceNavs;let i = index" class="nav-item">
            <a href id="invoiceLink{{i}}" class="nav-link" 
                (click)="invoiceNavigation(item, $event)"
                [class.active]="item.active" translate
            >{{item.name}}</a>
        </li>
    </ul>
</div>

<div class="sk-content-primary">
    <div class="p-200 max-width-lg min-width-lg">
        
        <sk-feedback *ngIf="invoiceOverdue" class="mb-100" type="danger"
            [subject]="'skBilling.invoicespage.overdue_invoices' | translate" [message]="'skBilling.invoicespage.overdue_invoices_message' | translate">
        </sk-feedback>
        
        <sk-feedback *ngIf="!connectWisePartner" class="mb-100" type="info" [dismissible]="true"
            [subject]="'skBilling.invoicespage.connectWise_integration' | translate" [message]="'skBilling.invoicespage.connectWise_integration_message' | translate">
            <button sk-button (click)="viewConnectWiseIntegration()" id="viewConnectWiseIntegration" class="btn btn-outline-secondary d-inline-block" translate>skBilling.invoicespage.add_integration</button>
        </sk-feedback>

        <ng-container *ngIf="!isLoading else empty">
            <div id="billingContactControl" *ngIf="getEmailAddress()" class="mb-150">
                <strong class="mr-50" translate>skBilling.invoicespage.billing_contact</strong>  {{getEmailAddress()}}
                <a href id="editBillingContact" (click)="editBillingContact($event)" class="mx-50" translate>skBilling.invoicespage.edit</a>
                <i class="material-icons md-20 icon-muted align-middle" ngbPopover="{{'skBilling.invoicespage.edit_help' | translate}}" triggers="mouseenter:mouseleave" placement="bottom">help_outline</i>
            </div>

            <div class="d-flex justify-content-between">
                <div class="text-muted" id="resultTally">{{'skBilling.invoicespage.showing_n_results' | translate: { length: transformInvoices(invoices, productType.value)?.length} }}</div>
                <div ngbDropdown class="btn-group pull-xs-right">
                    <button type="button" class="btn btn-basic dropdown-toggle" ngbDropdownToggle id="invoiceFilter" translate>{{productType.name}}</button>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <ng-container *ngFor="let item of productTypes;let index = index">
                            <a class="dropdown-item"  (click)="filterInvoices(item)"
                                id="invoiceFilterOption{{index}}" [class.active]="item.value === productType.value" translate>
                                {{item.name}}
                                <i *ngIf="item.value === productType.value" class="material-icons md-22 ml-100">check</i>
                            </a>
                        </ng-container>
                    </div>
                </div>
            </div>

            <table *ngIf="invoices.length; else empty"
                class="table table-hover sk-standard-wrap-table sk-sortable">
                <thead class="thead-white thead-sm">
                    <tr>
                        <th id="invoiceNumber" translate>skBilling.invoicespage.invoice_id</th>
                        <th id="productName" translate>skBilling.invoicespage.product</th>
                        <th id="total" translate>
                            <span *ngIf="openInvoicesTabActive()" translate>skBilling.invoicespage.invoice_amount</span>
                            <span *ngIf="paidInvoicesTabActive()" translate>skBilling.invoicespage.total_paid</span>
                        </th>
                        <th id="creditApplied" *ngIf="openInvoicesTabActive()" translate>skBilling.invoicespage.credit_applied</th>
                        <th id="amountOutstanding" *ngIf="openInvoicesTabActive()" translate>skBilling.invoicespage.amount_outstanding</th>
                        <th id="invoiceDate" translate>
                            <span *ngIf="openInvoicesTabActive()" translate>skBilling.invoicespage.due_by</span>
                            <span *ngIf="paidInvoicesTabActive()" translate>skBilling.invoicespage.date_paid</span>
                        </th>
                        <th id="overdue" *ngIf="openInvoicesTabActive()"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of transformInvoices(invoices, productType.value) ; let i = index">
                        <td class="primary">
                            <a id="invoiceDetails{{i}}" 
                                [routerLink]="['/', 'billing', 'invoice', item.transactionId]" 
                                [queryParams]="{ is: getInvoiceStatus() }"
                                >{{item.invoiceNumber}}</a>
                        </td>
                        <td id="productType{{i}}" translate>{{getProductDisplayName(item.productType)}}</td>
                        <td>{{item.total | currency: item.currencyCode}}</td>
                        <td *ngIf="openInvoicesTabActive()">{{item.amountApplied | currency: item.currencyCode}}</td>
                        <td *ngIf="openInvoicesTabActive()">{{item.amountOutstanding | currency: item.currencyCode}}</td>
                        <td id="invoiceDate{{i}}">
                            <span *ngIf="openInvoicesTabActive()">{{moment(item.dueDate).format('ll')}}</span>
                            <span *ngIf="paidInvoicesTabActive()">{{moment(item.paidDate).format('ll')}}</span>
                        </td>
                        <td id="overdue{{i}}" *ngIf="openInvoicesTabActive()">
                            <span *ngIf="moment(item.dueDate).isBefore()" class="badge badge-danger" translate>skBilling.invoicespage.overdue</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="pagedList.hasNext" class="d-flex justify-content-center">
                <button id="loadMore" type="button" class="btn btn-link sk-btn-combo" [disabled]="isLoadingMore" (click)="showMore()" translate>skBilling.invoicespage.load_more 
                    <i class="material-icons md-20">keyboard_arrow_down</i>
                </button>
            </div>

            <ng-template #empty>
                <div class="h-100 d-flex justify-content-center">
                    <div class="d-flex align-items-center justify-content-center flex-column">
                        <span class="material-icons md-72 icon-primary opacity-50">receipt</span>
                        <h5 class="mt-100" translate>skBilling.invoicespage.no_invoices_found</h5>
                        <p class="text-muted text-center w-75" *ngIf="openInvoicesTabActive() && !netSuiteCustomer?.isInvoicingEnabled" translate>skBilling.invoicespage.you_are_not_setup_for_invoicing</p>    
                        <p class="text-muted text-center w-75" *ngIf="openInvoicesTabActive() && netSuiteCustomer?.isInvoicingEnabled" translate>skBilling.invoicespage.you_are_setup_for_invoicing</p>    
                    </div>
                </div>
            </ng-template>

        </ng-container>
        <ng-template #empty>
            <sk-text-spinner message="{{'skBilling.invoicespage.fetching_invoices' | translate}}"></sk-text-spinner>
        </ng-template>
    </div>
</div>