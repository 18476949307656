import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BreadcrumbService } from '../shared/breadcrumbs/breadcrumb.service';
import { Invoice, Status } from 'src/app/shared/models/PlatformAccountingContract';
import * as moment from 'moment';
import { SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { getProductName } from 'src/app/shared/services/utilities';
@Component({
    selector: 'sk-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent extends SkyKickProductPage implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
	Status = Status;
    invoice: Invoice;
    moment: any = moment;
    fromInvoiceDueTab: boolean = false;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected taskManagerService: TaskManagerService,
        private breadcrumbService: BreadcrumbService
    ) { 
        super(taskManagerService)
    }

    ngOnInit(): void { 

        this.activatedRoute.data.pipe(
            takeUntil(this.onDestroy$),
            tap(res => {
                this.invoice = res.invoice;
                this.breadcrumbService.push(
                    ( this.invoice != null ? getProductName(this.invoice.productType) : '' ), 
                    '/billing/invoice/' + this.activatedRoute.snapshot.paramMap.get('transactionId')
                );
            })
        ).subscribe();

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['is'] == 'open') {
                this.fromInvoiceDueTab = true;
            }
        });
    }

    ngOnDestroy(): void {
        this.breadcrumbService.pop();
        this.onDestroy$.next();
    }

}
