import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActionDisplayService {

    constructor() { }
    
    /** primary button**/
    private submitPrimarySource = new Subject<void>();
    private submitPrimary$ = this.submitPrimarySource.asObservable();
    private setPrimaryIsBusySource = new BehaviorSubject(false);
    setPrimaryIsBusy$ = this.setPrimaryIsBusySource.asObservable();
    private setPrimaryIsDisabledSource = new BehaviorSubject(false);
    private setPrimaryIsVisibleSource = new BehaviorSubject(false);
    setPrimaryIsDisabled$ = this.setPrimaryIsDisabledSource.asObservable();
    setPrimaryIsVisible$ = this.setPrimaryIsVisibleSource.asObservable();

    submitPrimaryButton() {
        this.submitPrimarySource.next();
    }
    onSubmitPrimaryButton(handler: () => void): Subscription {
        return this.submitPrimary$.subscribe(handler);
    }
    setPrimaryIsBusy(value: boolean) {
        this.setPrimaryIsBusySource.next(value);
    }
    setPrimaryIsVisible(value: boolean) {
        this.setPrimaryIsVisibleSource.next(value);
    }
    setPrimaryIsDisabled(value: boolean) {
        this.setPrimaryIsDisabledSource.next(value);
    }

    
    /** secondary button**/
    private submitSecondarySource = new Subject<void>();
    private submitSecondary$ = this.submitSecondarySource.asObservable();
    private setSecondaryIsBusySource = new BehaviorSubject(false);
    setSecondaryIsBusy$ = this.setSecondaryIsBusySource.asObservable();
    private setSecondaryIsDisabledSource = new BehaviorSubject(false);
    setSecondaryIsDisabled$ = this.setSecondaryIsDisabledSource.asObservable();

    submitSecondaryButton() {
        this.submitSecondarySource.next();
    }
    onSubmitSecondaryButton(handler: () => void): Subscription {
        return this.submitSecondary$.subscribe(handler);
    }
    setSecondaryIsBusy(value: boolean) {
        this.setSecondaryIsBusySource.next(value);
    }
    setSecondaryIsDisabled(value: boolean) {
        this.setSecondaryIsDisabledSource.next(value);
    }

}
