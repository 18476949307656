import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { ApplyPayment, CustomerPaymentProfile, Invoice, Status } from 'src/app/shared/models/PlatformAccountingContract';
import { Subscription as AccountingSubscription } from 'src/app/shared/models/SubscriptionManagementContract';
import { ActionDisplayService } from '../../shared/services/action-display.service';
import { BillingResourcesService } from '../../shared/services/billing-resources.service';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { CreditCardService } from '../../shared/creditcard/creditcard.service';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: '[sk-invoice-payment]',
    templateUrl: './invoice-payment.component.html',
    styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    private subscription: Subscription;
    private applyPayment: ApplyPayment;
    moment: any = moment;
    invoice: Invoice;
	Status = Status;
    customerPaymentProfile: CustomerPaymentProfile;
    accountingSubscription: AccountingSubscription;
    displayAutoPay: boolean;
    fromInvoiceDueTab: boolean = false;

    constructor (
      protected activatedRoute: ActivatedRoute,
      private actionDisplayService: ActionDisplayService,
      private router: Router,
      private billingResourcesService: BillingResourcesService,
      private abstractUserProvider: AbstractUserProvider,
      private creditCardService: CreditCardService,
      private translateService: TranslateService,
      private toastrService: ToastrService
    ) {}

    ngOnInit(): void {
        this.actionDisplayService.setPrimaryIsVisible(false);
        this.actionDisplayService.setPrimaryIsDisabled(true);
        this.creditCardService.customerPaymentProfile$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            if (res) {
                this.customerPaymentProfile = res;
                this.actionDisplayService.setPrimaryIsDisabled(false);
            } else {
                this.actionDisplayService.setPrimaryIsDisabled(true);
            }
        });

        this.activatedRoute.params.subscribe(params => {
            this.billingResourcesService.getInvoiceDetail(params.transactionId).pipe(
                takeUntil(this.onDestroy$),
                tap(res => {
                    this.invoice = res;
                    this.actionDisplayService.setPrimaryIsVisible(this.invoice.status !== Status.Paid);

                    //check if it's a CM invoice to display Autopay
                    if (this.invoice.relatedSubscriptionId && this.invoice.currencyCode === 'USD') {
                        this.billingResourcesService.getSubscriptions().pipe(
                            takeUntil(this.onDestroy$),
                            tap(res => {
                                this.accountingSubscription = res.find(subscription => subscription.id === this.invoice.relatedSubscriptionId);
                                if (this.accountingSubscription && (this.invoice.status !== Status.Paid)) {
                                    this.displayAutoPay = true;
                                }
                            })
                        ).subscribe();
                    }

                })
            ).subscribe();
        });

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['is'].toLowerCase() === 'open') {
                this.fromInvoiceDueTab = true;
            }
        });
        
        this.subscription = this.actionDisplayService.onSubmitPrimaryButton(() => this.payInvoice());
        this.subscription.add(this.actionDisplayService.onSubmitSecondaryButton(() => this.downloadInvoice()));
    }

    payInvoice() {
        this.actionDisplayService.setPrimaryIsBusy(true);
        this.creditCardService.disableCustomerPaymentProfiles(true);

        this.applyPayment = {
            partnerId: this.abstractUserProvider.getCurrentUser().partnerId,
            invoiceNumber: this.invoice.invoiceNumber,
            invoiceTransactionId: this.invoice.transactionId.toString(),
            customerProfileId: this.customerPaymentProfile.profileId,
            paymentProfileId: this.customerPaymentProfile.paymentProfileId,
            paymentAmount: this.invoice.amountOutstanding,
            comments: ''
        };

        //check if it's a CM subscription invoice to set Autopay
        if (this.accountingSubscription) {
            this.applyPayment.autoPaySettings = {
                enabled: this.accountingSubscription.autoPayEnabled,
                subscriptionId: this.invoice.relatedSubscriptionId
            }
        }

        this.billingResourcesService.applyPayment(this.applyPayment).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe({
            complete: () => {
                this.creditCardService.disableCustomerPaymentProfiles(false);
                this.actionDisplayService.setPrimaryIsBusy(false);
                this.toastrService.success(this.translateService.instant('skBilling.payment_successful'));
                this.router.navigate(['/billing/invoices']);
            }, 
            error: res => {
                this.actionDisplayService.setPrimaryIsBusy(false);
                this.creditCardService.disableCustomerPaymentProfiles(false);
                if (res.error) {
                    this.toastrService.error(res.error.message);
                }
            }
        });
    }

    downloadInvoice() {
        this.actionDisplayService.setSecondaryIsDisabled(true);
        this.actionDisplayService.setSecondaryIsBusy(true);
        this.billingResourcesService.getInvoicePdf(this.invoice.transactionId).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe({
            next: res => {
                this.actionDisplayService.setSecondaryIsDisabled(false);
                this.actionDisplayService.setSecondaryIsBusy(false);

                var file = new Blob([res], {type: 'application/pdf'});
                var fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
            }, 
            error: () => {
                this.toastrService.error(this.translateService.instant('skBilling.unexpected_error'));
            }
        });
    }

    displayTotal(): number {
        return this.fromInvoiceDueTab ? this.invoice.amountOutstanding : this.invoice.total;
    }

    getProductPlanText(productType: number) {
        switch (productType) {
            case 1: return 'Backup Plan';
            case 2: return 'Migration Plan';
            case 3: return 'Cloud Manager Plan';
            case 4: return 'Security Manager Plan';
            default: return 'SkyKick Plan'
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.onDestroy$.next();
    }

}
