import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActionDisplayService } from '../../services/action-display.service';
import { ISkyKickPartnerPortalUserImpersonationDetailsProvider, SkyKickUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ControllerNames } from '../../models/ControllerNames';

@Component({
    selector: 'sk-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.scss'],
    host: {
		class:'sk-page-footer footer-light'
	}
})
export class ActionButtonsComponent implements OnInit, OnDestroy {
    ControllerNames = ControllerNames;
    displayFooter: boolean;
    controllerName: ControllerNames;
    isPrimaryBusy: boolean;
    isPrimaryDisabled: boolean;
    isPrimaryVisible: boolean;
    isSecondaryBusy: boolean;
    isSecondaryDisabled: boolean;
    private subscription: Subscription;

    private getCurrentActivatedRoute(activatedRoute: ActivatedRoute) {
        const ROUTE_DATA_FOOTER_ACTIONS = 'hasFooterActions';
        let children: ActivatedRoute[] = activatedRoute.children;

        if (children.length === 0) {
            return activatedRoute;
        }

        for (let child of children) {
            if (!child.routeConfig.data.hasOwnProperty(ROUTE_DATA_FOOTER_ACTIONS)) {
                return this.getCurrentActivatedRoute(child);
            }
            //recursive
            return this.getCurrentActivatedRoute(child);
        }
    }

    private renderFooterActions() {
        let currentRoute = this.getCurrentActivatedRoute(this.activatedRoute);
        this.displayFooter = currentRoute.snapshot.data.hasFooterActions;
        this.controllerName = currentRoute.snapshot.data.controllerName;
    }

    constructor(
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        private actionDisplayService: ActionDisplayService,
        private iSkyKickPartnerPortalUserImpersonationDetailsProvider: ISkyKickPartnerPortalUserImpersonationDetailsProvider,
        private userProvider: SkyKickUserProvider

    ) { 
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.renderFooterActions();
        });
    }

    ngOnInit(): void {
        this.subscription = this.actionDisplayService.setPrimaryIsBusy$.subscribe((flag: boolean) => this.isPrimaryBusy = flag);
        this.subscription.add(this.actionDisplayService.setPrimaryIsDisabled$.subscribe((flag: boolean) => this.isPrimaryDisabled = flag));
        this.subscription.add(this.actionDisplayService.setPrimaryIsVisible$.subscribe((flag: boolean) => this.isPrimaryVisible = flag));
        this.subscription.add(this.actionDisplayService.setSecondaryIsBusy$.subscribe((flag: boolean) => this.isSecondaryBusy = flag));
        this.subscription.add(this.actionDisplayService.setSecondaryIsDisabled$.subscribe((flag: boolean) => this.isSecondaryDisabled = flag));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    submitPrimaryButton() {
        this.actionDisplayService.submitPrimaryButton();
    }

    submitSecondaryButton() {
        this.actionDisplayService.submitSecondaryButton();
    }

    isImpersonating() {
        return this.iSkyKickPartnerPortalUserImpersonationDetailsProvider.getImpersonationDetails(this.userProvider.getCurrentUser()).isImpersonation;
    }

}
