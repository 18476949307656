<div class="p-200 max-width-md min-width-md">
    <div *ngIf="invoice">
        <sk-feedback  *ngIf="invoice.status === Status.Open && invoice.currencyCode !== 'USD'" class="mb-100" type="warning"
            [message]="'skBilling.invoicedetailpage.non_usd_currency' | translate: {url: 'https://support.skykick.com/hc/en-us/articles/360034486074-How-to-pay-for-SkyKick-services-via-Electronic-Funds-Transfer-EFT-'}">
        </sk-feedback>

        <sk-feedback *ngIf="invoice.status === Status.Paid && fromInvoiceDueTab" class="mb-100" type="warning"
            [message]="'skBilling.invoicedetailpage.payment_pending' | translate">
        </sk-feedback>
        
        <div class="d-flex mb-100">
          <div class="badge badge-success" *ngIf="this.invoice.status === Status.Paid">
          {{'skBilling.invoicedetailpage.paid_on' | translate}} {{moment(invoice.paidDate).format('ll')}}</div>
        </div>
        <div class="d-flex mb-100">
            <h3>{{'skBilling.invoice_number' | translate: {number: invoice.invoiceNumber} }}
                <div class="d-inline" (mouseleave)="p.close()">
                    <ng-template #popContent>
                        <span class="mr-50" translate>skBilling.questions</span> 
                        <a [routerLink]="['/', 'manageplans']" translate>skBilling.view_details</a><br/>
                        <span class="mr-50" translate>skBilling.go_to_our_help</span> 
                        <a href="https://support.skykick.com/hc/en-us/articles/1500002459082-SkyKick-billing-overview" target="_blank" translate>skBilling.learn_more</a> 
                    </ng-template>
                    <a class="btn sk-btn-icon px-0 height-inherit border-0" 
                        [ngbPopover]="popContent" placement="right" popoverClass="sk-popover-shim" 
                        #p="ngbPopover" (mouseenter)="p.open()">
                        <i class="material-icons md-20 icon-muted">help_outline</i>
                    </a>        
                </div>
            </h3>
        </div>

        <table>
            <tbody>
                <tr>
                    <td translate>skBilling.invoicedetailpage.date_created</td>
                    <td class="pl-100">{{moment(invoice.createdDate).format('ll')}}</td>    
                </tr>
                <tr>
                    <td translate>skBilling.invoicedetailpage.due_date</td>
                    <td class="pl-100">{{moment(invoice.dueDate).format('ll')}}</td>
                </tr>
            </tbody>
        </table>

    </div>
    <sk-text-spinner *ngIf="!invoice" message="{{'skBilling.invoicedetailpage.fetching_invoice_details' | translate}}"></sk-text-spinner>
</div>

<router-outlet></router-outlet>
