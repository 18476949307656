// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\AutoPaySubscription.cs
export interface AutoPaySubscription {
    subscription: Subscription;
    salesOrderId: string;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\EmailNotificationRequest.cs
export interface EmailNotificationRequest {
    subscriptionId: string;
    transactionId: number;
    type: EmailNotificationType;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\EmailNotificationRequest.cs
export enum EmailNotificationType {
    TrialExpiration = 'TrialExpiration',
    RenewalApproaching = 'RenewalApproaching',
    RenewalConfirmation = 'RenewalConfirmation',
    PastDue = 'PastDue',
    AutoPaySuccess = 'AutoPaySuccess',
    AutoPayFailure = 'AutoPayFailure',
    Suspended = 'Suspended',
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Estimate.cs
export interface Estimate {
    id: string;
    items: EstimateLineItem[];
    taxTotal: number;
    total: number;
    currency: Currency;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Estimate.cs
export interface Currency {
    currencyCode: string;
    currencySymbol: string;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Estimate.cs
export interface EstimateLineItem {
    prorated: boolean;
    proratedDays?: number;
    name: string;
    price: number;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Partner.cs
export interface Partner {
    companyName: string;
    city: string;
    countryCode: string;
    owner: string;
    currencyId: string;
    testPartner: boolean;
    primaryContactEmail: string;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\PlatformPlanDetails.cs
export interface PlatformPlanDetails extends Subscription {
    userLicensesAvailable: number;
    discount: PlanDiscount;
    userLicenses: number;
    userLicensesInUse: number;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\PlatformPlanDetails.cs
export interface PlanDiscount {
    backup: number;
    bundle: number;
    migrations: number;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\RetroactiveSubscription.cs
export interface RetroactiveSubscription extends Subscription {
    orderAlreadyExistsInBillingSystem?: boolean;
    overrideEndDate?: string;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\State.cs
export enum State {
    Active = 'Active',
    Trial = 'Trial',
    Suspended = 'Suspended',
    Cancelled = 'Cancelled',
    RenewalApproaching = 'RenewalApproaching',
    PastDue = 'PastDue',
    ActiveFree = 'ActiveFree',
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Subscription.cs
export interface Subscription {
    id: string;
    doNotRenew: boolean;
    isCancellable: boolean;
    externalId: string;
    productCatalogId: string;
    catalogItems: Item[];
    productTypeId: string;
    partnerId?: string;
    distributorId?: string;
    userId?: string;
    contactEmail: string;
    autoPayEnabled: boolean;
    trial: boolean;
    status: Status;
    currency: string;
    billingFrequency: BillingFrequency;
    planType: string;
    startDate?: string;
    termStartDate?: string;
    nextChargeDate?: string;
    termsAccepted: string[];
    estimateId?: string;
    state: string;
    originationSource: OriginationSource;
    nDaysFree?: number;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Subscription.cs
export interface Item {
    id: string;
    quantity: number;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Subscription.cs
export enum BillingFrequency {
    Monthly = 'Monthly',
    Annual = 'Annual',
    AnnualMonthlyCommit = 'AnnualMonthlyCommit',
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Subscription.cs
export enum Status {
    Active = 'Active',
    Suspended = 'Suspended',
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\Subscription.cs
export enum OriginationSource {
    Traditional = 'Traditional',
    Marketplace = 'Marketplace',
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\SubscriptionFilterRequest.cs
export interface SubscriptionFilterRequest {
    billingFrequency?: BillingFrequency;
    distributorId?: string;
    doNotRenew?: boolean;
    isCancellable?: boolean;
    endsOnOrBefore?: string;
    isTrial?: boolean;
    originationSource?: OriginationSource;
    partnerId?: string;
    state?: State;
    termStartsOnOrBefore?: string;
}

// ..\..\SkyKick.Platform.SubscriptionManagement\SubscriptionManagement\Contract\Models\TermsAndConditions.cs
export interface TermsAndConditions {
    id: string;
    typeName: string;
    rawHtml: string;
    acceptanceRequired: boolean;
    productTypeId: string;
}
