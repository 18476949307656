<div class="modal-header bg-white pb-0">
    <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
            <h3 class="modal-title font-weight-normal" translate>billing_contact.contact_information</h3>
        </div>
    </div>

    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close" (click)="activeModal.dismiss()" id="closeBtn">
    </button>
</div>
<div class="modal-body">
    <p class="text-muted" translate>billing_contact.summary</p>
    <form [formGroup]="form" novalidate class="mt-100">
        <fieldset>
            <label for="email" class="form-label" translate>billing_contact.email_address</label>
            <input type="text" class="form-control" formControlName="email" id="email" 
            [class.is-invalid]="form.get('email').invalid && !form.get('email').pristine"/>
            <small *ngIf="form.get('email').invalid && !form.get('email').pristine" class="invalid-feedback" translate>skBilling.creditcardmodal.format_error</small>
        </fieldset>
    </form>

    <div *ngIf="errors" class="row mt-3">
        <div class="col">
            <div class="is-invalid">
                <small class="invalid-feedback" *ngFor="let error of errors" [innerText]="error"></small>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-start">
    <button id="submitBtn" sk-spinner-button [color]="'primary'" (click)="updateContact()" [isSpinning]="this.form.disabled" 
        [disabled]="this.form.invalid">{{ 'billing_contact.update' | translate }}
    </button>
    <button id="cancelBtn" type="button" class="btn btn-outline-secondary" [class.disabled]="this.form.disabled" (click)="activeModal.dismiss()" translate>billing_contact.cancel</button>
</div>