import { inject } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BillingResourcesService } from '../services/billing-resources.service';
import { ResolveFn } from '@angular/router';

export const ConnectWisePartnerResolver: ResolveFn<any> = () => {
  return inject(BillingResourcesService).getConnectWisePartner().pipe(
    catchError(() => {
      return of(null);
    })
  );
};
