import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BillingResourcesService } from '../../services/billing-resources.service';

@Component({
    selector: 'sk-billing-contact',
    templateUrl: './billing-contact.component.html',
    styleUrls: ['./billing-contact.component.scss'],
    host: {
        '[class.modal-content]': 'true'
    }
})
export class BillingContactComponent implements OnInit {
    @Input() billingContact: string;
    form: UntypedFormGroup;
    errors: Array<string> = [];

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: UntypedFormBuilder,
        private billingResourcesService: BillingResourcesService,
        private translateService: TranslateService,
        private toastrService: ToastrService
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: [this.billingContact, [Validators.required, Validators.email]]
        });
    }

    updateContact() {
        this.form.disable();
        this.billingResourcesService.patchBillingEmail(this.form.get('email').value).subscribe({
            complete: () => {
                this.toastrService.success(this.translateService.instant('billing_contact.successfully_updated'));
                this.activeModal.close(this.form.get('email').value);    
            }, 
            error: () => {
                this.toastrService.error(this.translateService.instant('skBilling.unexpected_error'));
                this.form.enable();
            }
        });
    }
}
