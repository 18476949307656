import { SkyKickProduct } from "../models/SkyKickProduct.model";

export const SkyKickProducts: SkyKickProduct[] = [
    {
        cardHeaderTitle: 'Cloud Manager Plan',
        displayName: 'Cloud Manager',
        logo: '/assets/images/skykick-manager.svg',
        productLink: 'https://cm.skykick.com/introduction',
        productLinkText: 'Learn more about Cloud Manager',
        productTypeId: 'be60065c-8079-4e84-8eb1-ba87c341004f',
        routeName: 'cloudmanager',
        summary: 'The all-new Cloud Manager is a single application for IT Providers to securely administer thousands of tasks across SaaS, PaaS, IaaS, and devices in a fraction of time.'
    },
    {
        cardHeaderTitle: 'Security Manager Plan',
        displayName: 'Security Manager',
        logo: '/assets/images/skykick-security.svg',
        productLink: 'https://sm.skykick.com/introduction',
        productLinkText: 'Learn more about Security Manager',
        productTypeId: '82424be6-4e39-47b6-844d-bdc24f381eac',
        routeName: 'securitymanager',
        summary: 'Streamline security delivery and accelerate growth with 1-click solutions for top M365 scenarios, including improving their Secure Store, across all Microsoft 365 customer tenants.'
    }
];