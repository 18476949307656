import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SkykickPlatformPage, TaskManagerService } from '@skykick/core';
import { BreadcrumbService } from '../shared/breadcrumbs/breadcrumb.service';

@Component({
    selector: 'main[sk-billing]',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss']
})
export class BillingComponent extends SkykickPlatformPage implements OnInit, OnDestroy {

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected taskManagerService: TaskManagerService,
        private breadcrumbService: BreadcrumbService
    ) { 
        super(activatedRoute, taskManagerService);
    }

    ngOnInit(): void {
        this.breadcrumbService.push('skBilling.invoices', '/billing/invoices');
    }

    ngOnDestroy(): void {
        this.breadcrumbService.pop();
    }

}
