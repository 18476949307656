import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Invoice } from 'src/app/shared/models/PlatformAccountingContract';
import { BillingResourcesService } from '../services/billing-resources.service';

export const InvoiceResolver: ResolveFn<Invoice> = (
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ) => {
    return inject(BillingResourcesService).getInvoiceDetail(activatedRouteSnapshot.params['transactionId']);
};
