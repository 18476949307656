<div class="p-200 max-width-md min-width-md">
    <ng-container *ngFor="let platformPlanDetail of platformPlanDetails; let index = index">
        <div *ngIf="platformPlanDetail.trial" [class.mt-200]="index > 0">
            <h3 translate>platform_plan_page.start_a_skyKick_platform_plan</h3>
            <p class="mt-100" translate>platform_plan_page.your_teams_account <a href skContactSales id="contactSales" translate>platform_plan_page.contact_sales</a></p>

            <div class="card sk-card">
                <div class="card-block">
                    <h5>{{moment(platformPlanDetail.nextChargeDate).toNow(true)}} {{'platform_plan_page.left_on_trial_plan' | translate}}</h5>
                    <p class="mt-100" translate>platform_plan_page.currently_no_platform_plan</p>
                    <button type="button" (click)="viewPlanOptions(platformPlanDetail.productTypeId)" class="btn btn-primary" id="planOptions" translate>platform_plan_page.view_plan_options</button>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!platformPlanDetail.trial">

            <div *ngIf="platformPlanDetail.state === State.Suspended || platformPlanDetail.state === State.Cancelled"
                [class.mt-200]="index > 0">
                <h3>{{'platform_plan_page.skykick_platform_plan_for' | translate: { name: companyInfo.name } }}</h3>
                <p class="mt-100" translate>platform_plan_page.your_teams_account <a href skContactSales id="contactSales" translate>platform_plan_page.contact_sales</a></p>

                <div class="card sk-card">
                    <div class="card-block">
                        <div class="d-flex justify-content-between">
                            <h5 translate>platform_plan_page.renew_your_subscription</h5>
                            <span class="badge badge-danger">{{ 'platform_plan_page.expired_date' | translate:{ date: formatMoment(getCommitmentRenewalDate(platformPlanDetail))} }}</span>
                        </div>
                        <p class="mt-100" translate>platform_plan_page.choose_to_renew_or_update</p>
                        <button type="button" (click)="viewPlanOptions(platformPlanDetail.productTypeId)" class="btn btn-primary" id="planOptions" translate>platform_plan_page.view_renewal_options</button>
                    </div>
                </div>
            </div>

            <div id="SkyKickProduct{{index}}" *ngIf="(platformPlanDetail.state !== State.Suspended && platformPlanDetail.state !== State.Cancelled)"
                [class.mt-200]="index > 0">

                <sk-feedback *ngIf="isMarketplaceSubscription(platformPlanDetail)" class="mb-100" type="info"
                    [subject] ="'platform_plan_page.marketplace_order' | translate">
                    <span translate>platform_plan_page.subscribing_through_distributor</span>
                    <span *ngIf="this.distributor && this.distributor.marketplaceUrl" class="ml-50" [innerHTML]="'platform_plan_page.visit_your_marketplace' | translate: {url: this.distributor.marketplaceUrl}"></span>
                </sk-feedback>

                <div class="card sk-card">
                    <div class="card-header sk-card-header">
                        <h4 translate [translateParams]="{ productType: getSkyKickProduct(platformPlanDetail.productTypeId).displayName }">platform_plan_page.product_plan</h4>
                    </div>
                    <div class="card-block">
                        <div class="row">
                            <div class="col-12">
                                <span translate class="mr-50">platform_plan_page.plan_selected</span>
                                <strong *ngIf="platformPlanDetail.planType === 'basic'" id="planType{{index}}" translate>platform_plan_page.basic_plan</strong>
                                <strong *ngIf="platformPlanDetail.planType === 'standard'" id="planType{{index}}" translate>platform_plan_page.standard_plan</strong>
                                <strong *ngIf="platformPlanDetail.planType === 'pro'" id="planType{{index}}" translate>platform_plan_page.professional_plan</strong>
                                <p translate>platform_plan_page.your_teams_account</p>
                            </div>
                        </div>

                        <div class="row mt-50">
                            <div class="col-6">
                                <strong translate>platform_plan_page.plan_details</strong>
                                <table class="table table-borderless sk-basic-table sk-compact-table mt-50">
                                    <tbody>
                                        <ng-container>
                                            <tr>
                                                <td translate>platform_plan_page.subscription_status</td>
                                                <td id="status{{index}}">
                                                    <span *ngIf="isActive(platformPlanDetail) && !platformPlanDetail.doNotRenew"
                                                        class="badge badge-default" translate>platform_plan_page.subscription_statuses.active</span>
                                                    <span *ngIf="isActive(platformPlanDetail) && platformPlanDetail.doNotRenew"
                                                        class="badge badge-danger" translate>platform_plan_page.subscription_statuses.do_not_renew</span>
                                                    <span *ngIf="(platformPlanDetail.state === State.ActiveFree) && !platformPlanDetail.doNotRenew"
                                                        class="badge badge-default" translate>platform_plan_page.subscription_statuses.active_free</span>
                                                    <span *ngIf="(platformPlanDetail.state === State.ActiveFree) && platformPlanDetail.doNotRenew"
                                                        class="badge badge-danger" translate>platform_plan_page.subscription_statuses.cancelled</span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="!platformPlanDetail.doNotRenew">
                                            <tr>
                                                <td translate>platform_plan_page.start_date</td>
                                                <td id="startDate{{index}}" class="w-50">{{formatDate(platformPlanDetail.termStartDate)}}</td>
                                            </tr>
                                            <tr>
                                                <td translate>platform_plan_page.renewal_date</td>
                                                <td id="renewalDate{{index}}">{{formatMoment(getCommitmentRenewalDate(platformPlanDetail))}}</td>
                                            </tr>
                                            <tr>
                                                <td translate>platform_plan_page.billing_cycle</td>
                                                <td id="billingFrequency{{index}}">
                                                    <span *ngIf="platformPlanDetail.billingFrequency === BillingFrequency.Monthly" translate>platform_plan_page.monthly</span>
                                                    <span *ngIf="platformPlanDetail.billingFrequency === BillingFrequency.AnnualMonthlyCommit" translate>platform_plan_page.billing_cycle_annual_monthly_commit</span>
                                                    <span *ngIf="platformPlanDetail.billingFrequency === BillingFrequency.Annual" translate>platform_plan_page.billing_cycle_annual</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td translate>platform_plan_page.commit_length</td>
                                                <td id="commitLength{{index}}">
                                                    <span *ngIf="platformPlanDetail.billingFrequency === BillingFrequency.Monthly" translate>platform_plan_page.commit_length_monthly</span>
                                                    <span *ngIf="platformPlanDetail.billingFrequency === BillingFrequency.AnnualMonthlyCommit" translate>platform_plan_page.commit_length_annual_monthly_commit</span>
                                                    <span *ngIf="platformPlanDetail.billingFrequency === BillingFrequency.Annual" translate>platform_plan_page.commit_length_annual</span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="platformPlanDetail.doNotRenew">
                                            <tr>
                                                <td translate>platform_plan_page.end_date</td>
                                                <td *ngIf="platformPlanDetail.state === State.ActiveFree">{{formatMoment(moment(platformPlanDetail.termStartDate).subtract(1, 'days'))}}</td>
                                                <td *ngIf="platformPlanDetail.state !== State.ActiveFree">{{formatMoment(getCommitmentRenewalDate(platformPlanDetail).subtract(1, 'days'))}}</td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td translate>platform_plan_page.total_user_licenses</td>
                                            <td id="totalUserLicenses{{index}}">{{platformPlanDetail.userLicenses}}</td>
                                        </tr>
                                        <tr>
                                            <td translate>platform_plan_page.licenses_used</td>
                                            <td id="licensesUsed{{index}}">{{platformPlanDetail.userLicensesInUse}}/{{platformPlanDetail.userLicenses}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div id="footer{{index}}" class="card-footer" *ngIf="showFooterWithActions(platformPlanDetail)">
                        <p id="willNotRenewText{{index}}" class="text-muted mb-0" *ngIf="platformPlanDetail.doNotRenew && isActive(platformPlanDetail)">
                            {{'platform_plan_page.renewal_flow.your_plan_will_not_renew' | translate}}
                            <a href (click)="renewSubscription($event, platformPlanDetail)" id="renewSubscription" translate>platform_plan_page.renewal_flow.renew_subscription</a>
                        </p>

                        <p id="cancelledText{{index}}" class="text-muted mb-0" *ngIf="platformPlanDetail.doNotRenew && platformPlanDetail.state === State.ActiveFree" >
                            {{'platform_plan_page.you_have_cancelled_your_subscription' | translate: {date: moment(platformPlanDetail.termStartDate).subtract(1, 'days').format('ll')} }}
                            <a href (click)="renewSubscription($event, platformPlanDetail)" id="renewSubscription" translate>platform_plan_page.renewal_flow.renew_subscription</a>
                        </p>

                        <p id="freePeriodText{{index}}" class="text-muted mb-0" *ngIf="!platformPlanDetail.doNotRenew && platformPlanDetail.isCancellable && platformPlanDetail.state === State.ActiveFree">
                            {{'platform_plan_page.your_free_period_ends' | translate: {date: formatDate(platformPlanDetail.termStartDate)} }} <a href (click)="cancelSubscription($event, platformPlanDetail)" id="cancelSubscription" translate>platform_plan_page.cancel_subscription</a>
                        </p>

                        <p id="autoRenewText{{index}}" class="text-muted mb-0" *ngIf="!platformPlanDetail.doNotRenew && isActive(platformPlanDetail)">
                            {{'platform_plan_page.plan_automatically_renew_date' | translate: {date: formatMoment(getCommitmentRenewalDate(platformPlanDetail))} }} <a href (click)="turnOffRenew($event, platformPlanDetail)" id="doNotRenew" translate>platform_plan_page.subscription_statuses.do_not_renew</a>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let platformPlanDetail of platformPlanDetails; let index = index">
        <ng-container *ngIf="!platformPlanDetail.trial && platformPlanDetail.productTypeId !== '82424be6-4e39-47b6-844d-bdc24f381eac'">
            <div id="productDiscounts{{index}}" *ngIf="(platformPlanDetail.state !== State.Suspended && platformPlanDetail.state !== State.Cancelled)" class="mt-200">

                <div class="card sk-card">
                    <div class="card-header sk-card-header">
                        <h4 translate>platform_plan_page.product_discounts</h4>
                    </div>
                    <div class="card-block">
                        <div class="row">
                            <div class="col-6">
                                <table class="table sk-basic-table sk-compact-table">
                                    <tr>
                                        <td>{{getDiscountTitleText('backup')}}</td>
                                        <td id="backupDiscount{{index}}" class="w-50 text-success font-weight-medium">{{platformPlanDetail.discount.backup}}%</td>
                                    </tr>
                                    <tr>
                                        <td>{{getDiscountTitleText('migrations')}}</td>
                                        <td id="migrationsDiscount{{index}}" class="text-success font-weight-medium">{{platformPlanDetail.discount.migrations}}%</td>
                                    </tr>
                                    <tr>
                                        <td>{{getDiscountTitleText('bundle')}}</td>
                                        <td id="bundleDiscount{{index}}"  class="text-success font-weight-medium">{{platformPlanDetail.discount.bundle}}%</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>
    </ng-container>

    <!-- Hide product cards if partner already has at least one active subscription -->
    <ng-container *ngIf="!hasActiveSubscription()">
        <div id="{{item.routeName}}ProductCard" *ngFor="let item of notPurchasedSkyKickProducts; let i = index" class="card sk-card" [class.mt-200]="platformPlanDetails?.length || (i > 0)">
            <div class="card-header sk-card-header d-flex justify-content-between">
                <h4>{{item.cardHeaderTitle}}</h4>
            </div>
            <div class="card-block">
                <div class="text-center">
                    <img class="img-fluid my-50" src="{{item.logo}}" alt="{{item.displayName}}" />
                    <p class="px-300 mx-300 mt-150">{{item.summary}}</p>
                    <a [href]="getProductLink(item.productTypeId)">{{item.productLinkText}}</a>
                </div>
            </div>
        </div>
    </ng-container>
</div>

