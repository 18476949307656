<div class="card mt-200">
    <div class="card-header d-flex align-items-center sk-card-header">
        <h5 class="mr-50" translate>skBilling.invoicedetailpage.payment</h5>

        <div (mouseleave)="p.close()">
            <ng-template #popContent>
                <span class="mr-50" translate>skBilling.payment_help</span>
                <a href="https://support.skykick.com/hc/en-us/articles/360035625493-Payment-options-for-SkyKick-services" target="_blank" translate>skBilling.learn_more</a>    
            </ng-template>
            <a class="btn sk-btn-icon px-0 height-inherit border-0" 
                [ngbPopover]="popContent" popoverClass="sk-popover-shim" 
                #p="ngbPopover" (mouseenter)="p.open()">
                <i class="material-icons md-20 icon-muted">help_outline</i>
            </a>        
        </div>
    </div>
    <div class="card-block">
        <fieldset>
            <p class="text-muted" [innerHTML]="'skBilling.customerprofiles.summary' | translate: { url : 'https://support.skykick.com/hc/en-us/articles/360034486074-How-to-pay-for-SkyKick-services-via-Electronic-Funds-Transfer-EFT-' }"></p>
            <form [formGroup]="form" *ngIf="!isLoading && customerPaymentProfiles.length">
                <table class="table table-borderless sk-basic-table">
                    <tr>
                        <th translate>skBilling.customerprofiles.your_credit_cards</th>
                        <th translate>skBilling.customerprofiles.expires_on</th>
                    </tr>
                    <tbody>
                        <tr *ngFor="let item of customerPaymentProfiles; index as index">
                            <td>
                                <ng-container *ngIf="displayOnly">
                                    <img *ngIf="item.cardType" src="/assets/images/flat-rounded/{{item.cardType}}.svg" width="40" class="mr-50">
                                    <span translate class="mx-50">skBilling.customerprofiles.card_ending_in</span> {{item.cardNumber}}
                                </ng-container>

                                <div *ngIf="!displayOnly" class="form-check">
                                    <input type="radio" id="paymentProfile{{index}}" name="paymentProfile" class="form-check-input" 
                                        formControlName="paymentProfile" [value]="item">
                                    <label class="form-check-label" for="paymentProfile{{index}}">
                                        <img *ngIf="item.cardType" src="/assets/images/flat-rounded/{{item.cardType}}.svg" width="40" class="mr-50">
                                        <span translate class="mx-50">skBilling.customerprofiles.card_ending_in</span> {{item.cardNumber}}
                                    </label>
                                </div>                                
                            </td>
                            <td>{{item.expirationDate}}</td>
                            <td><span *ngIf="item.isDefault" class="badge badge-default" translate>skBilling.customerprofiles.default_card</span></td>
                        </tr>
                </tbody>
                </table>
            </form>
        </fieldset>
    
        <sk-text-spinner *ngIf="isLoading" message="{{'skBilling.customerprofiles.fetching_customer_profiles' | translate}}"></sk-text-spinner>
    
        <button id="btnManageCreditCards" (click)="manageCreditCards()" [class.disabled]="this.form.disabled" type="button" class="btn btn-link" translate>skBilling.customerprofiles.manage_credit_cards</button>
        <!-- <button type="button" class="btn btn-secondary mr-50" (click)="getCustomerProfileRequest()">getCustomerProfileRequest</button>
        <pre *ngFor="let item of paymentProfiles">{{item | json}}</pre> -->
    </div>
</div>
