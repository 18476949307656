import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerPaymentProfile } from 'src/app/shared/models/PlatformAccountingContract';

@Injectable({
    providedIn: 'root'
})
export class CreditCardService {
    private customerPaymentProfileSubject: BehaviorSubject<CustomerPaymentProfile> = new BehaviorSubject(null);
    public customerPaymentProfile$: Observable<CustomerPaymentProfile> = this.customerPaymentProfileSubject.asObservable();
    public setCustomerPaymentProfile(value: CustomerPaymentProfile | null) {
        this.customerPaymentProfileSubject.next(value);
    }

    private disableCustomerPaymentProfilesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public disableCustomerPaymentProfiles$: Observable<boolean> = this.disableCustomerPaymentProfilesSubject.asObservable();
    public disableCustomerPaymentProfiles(value: boolean) {
        this.disableCustomerPaymentProfilesSubject.next(value);
    }

    constructor() { }
}
